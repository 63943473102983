import {
  MODEL_ANALYTICS_FAIL,
  MODEL_ANALYTICS_REQUEST,
  MODEL_ANALYTICS_RESET,
  MODEL_ANALYTICS_SUCCESS,
} from "../constants/analyticsContants"

export const modelPriceTrendReducer = (
  state = { modelPriceTrendData: [], count: 0, range: [], loading: true },
  action
) => {
  switch (action.type) {
    case MODEL_ANALYTICS_REQUEST:
      return { ...state, loading: true }
    case MODEL_ANALYTICS_SUCCESS:
      return {
        loading: false,
        modelPriceTrendData: action.payload.organizedData,
        carCount: action.payload.carCount,
      }
    case MODEL_ANALYTICS_FAIL:
      return { loading: false, error: action.payload }
    case MODEL_ANALYTICS_RESET:
      return { modelPriceTrendData: [], count: 0, range: [], loading: true }
    default:
      return state
  }
}
