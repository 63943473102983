export const TAG_LIST_REQUEST = "TAG_LIST_REQUEST"
export const TAG_LIST_SUCCESS = "TAG_LIST_SUCCESS"
export const TAG_LIST_FAIL = "TAG_LIST_FAIL"
export const TAG_LIST_RESET = "TAG_LIST_RESET"

export const TAG_CREATE_REQUEST = "TAG_CREATE_REQUEST"
export const TAG_CREATE_SUCCESS = "TAG_CREATE_SUCCESS"
export const TAG_CREATE_FAIL = "TAG_CREATE_FAIL"
export const TAG_CREATE_RESET = "TAG_CREATE_RESET"

export const TAG_DETAIL_REQUEST = "TAG_DETAIL_REQUEST"
export const TAG_DETAIL_SUCCESS = "TAG_DETAIL_SUCCESS"
export const TAG_DETAIL_FAIL = "TAG_DETAIL_FAIL"
export const TAG_DETAIL_RESET = "TAG_DETAIL_RESET"

export const TAG_UPDATE_REQUEST = "TAG_UPDATE_REQUEST"
export const TAG_UPDATE_SUCCESS = "TAG_UPDATE_SUCCESS"
export const TAG_UPDATE_FAIL = "TAG_UPDATE_FAIL"
export const TAG_UPDATE_RESET = "TAG_UPDATE_RESET"

export const TAG_DELETE_REQUEST = "TAG_DELETE_REQUEST"
export const TAG_DELETE_SUCCESS = "TAG_DELETE_SUCCESS"
export const TAG_DELETE_FAIL = "TAG_DELETE_FAIL"
export const TAG_DELETE_RESET = "TAG_DELETE_RESET"
