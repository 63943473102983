import React from "react"
import DropDownMenu from "../selection/Dropdown"
import useAuthUser from "../../state/customHooks/user/useAuthUser"
import NotificationBell from "./NotificationBell"
import "./NotificationBell.scss"
import "./Header.scss"
import Logo from "./Logo"

const Header = ({ drawerClick }) => {
  const [user] = useAuthUser()

  return (
    <>
      <header className="flex jcsb aic header">
        <div className="flex aic">
          {user._admin && (
            <div className="sidedrawer-toggle-button" onClick={drawerClick} />
          )}

          <Logo />
        </div>

        <div className="flex aic">
          <NotificationBell />
          <DropDownMenu user={user} />
        </div>
      </header>
    </>
  )
}

export default Header
