import {
  OPPORTUNITY_CREATE_FAIL,
  OPPORTUNITY_CREATE_REQUEST,
  OPPORTUNITY_CREATE_RESET,
  OPPORTUNITY_CREATE_SUCCESS,
  OPPORTUNITY_DELETE_FAIL,
  OPPORTUNITY_DELETE_REQUEST,
  OPPORTUNITY_DELETE_RESET,
  OPPORTUNITY_DELETE_SUCCESS,
  OPPORTUNITY_LIST_FAIL,
  OPPORTUNITY_LIST_REQUEST,
  OPPORTUNITY_LIST_SUCCESS,
  OPPORTUNITY_UPDATE_FAIL,
  OPPORTUNITY_UPDATE_REQUEST,
  OPPORTUNITY_UPDATE_RESET,
  OPPORTUNITY_UPDATE_SUCCESS,
} from "../constants/opportunityConstants"

export const opportunityListReducer = (
  state = { loading: true, opportunities: [] },
  action
) => {
  switch (action.type) {
    case OPPORTUNITY_LIST_REQUEST:
      return { loading: true, opportunities: [] }
    case OPPORTUNITY_LIST_SUCCESS:
      return {
        loading: false,
        opportunities: action.payload.opportunities,
        page: action.payload.page,
        pages: action.payload.pages,
        count: action.payload.count,
      }
    case OPPORTUNITY_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const opportunityUpdateReducer = (
  state = { loading: false, success: false, opportunity: {} },
  action
) => {
  switch (action.type) {
    case OPPORTUNITY_UPDATE_REQUEST:
      return { loading: true, success: false, opportunity: {} }
    case OPPORTUNITY_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        opportunity: action.payload,
      }
    case OPPORTUNITY_UPDATE_RESET:
      return {
        loading: false,
        success: false,
      }
    case OPPORTUNITY_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const opportunityCreateReducer = (
  state = { loading: false, success: false, opportunity: {} },
  action
) => {
  switch (action.type) {
    case OPPORTUNITY_CREATE_REQUEST:
      return { loading: true, success: false, opportunity: {} }
    case OPPORTUNITY_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        opportunity: action.payload,
      }
    case OPPORTUNITY_CREATE_RESET:
      return {
        loading: false,
        success: false,
      }
    case OPPORTUNITY_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const opportunityDeleteReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case OPPORTUNITY_DELETE_REQUEST:
      return { loading: true, success: false }
    case OPPORTUNITY_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case OPPORTUNITY_DELETE_RESET:
      return {
        loading: false,
        success: false,
      }
    case OPPORTUNITY_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
