import {
  SELLER_SPECIFIC_LIST_FAIL,
  SELLER_SPECIFIC_LIST_REQUEST,
  SELLER_SPECIFIC_LIST_RESET,
  SELLER_SPECIFIC_LIST_SUCCESS,
} from "../constants/sellerSpecificConstants"

export const sellerSpecificListReducer = (
  state = { sellers: [], loading: true },
  action
) => {
  switch (action.type) {
    case SELLER_SPECIFIC_LIST_REQUEST:
      return { loading: true, sellers: [] }
    case SELLER_SPECIFIC_LIST_SUCCESS:
      return {
        loading: false,
        sellers: action.payload.sellers,
      }
    case SELLER_SPECIFIC_LIST_RESET:
      return {
        loading: false,
        sellers: [],
      }
    case SELLER_SPECIFIC_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
