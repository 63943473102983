export const SELLER_LIST_REQUEST = "SELLER_LIST_REQUEST"
export const SELLER_LIST_SUCCESS = "SELLER_LIST_SUCCESS"
export const SELLER_LIST_FAIL = "SELLER_LIST_FAIL"
export const SELLER_LIST_RESET = "SELLER_LIST_RESET"

export const SELLER_CREATE_REQUEST = "SELLER_CREATE_REQUEST"
export const SELLER_CREATE_SUCCESS = "SELLER_CREATE_SUCCESS"
export const SELLER_CREATE_FAIL = "SELLER_CREATE_FAIL"
export const SELLER_CREATE_RESET = "SELLER_CREATE_RESET"

export const SELLER_DETAIL_REQUEST = "SELLER_DETAIL_REQUEST"
export const SELLER_DETAIL_SUCCESS = "SELLER_DETAIL_SUCCESS"
export const SELLER_DETAIL_FAIL = "SELLER_DETAIL_FAIL"
export const SELLER_DETAIL_RESET = "SELLER_DETAIL_RESET"

export const SELLER_UPDATE_REQUEST = "SELLER_UPDATE_REQUEST"
export const SELLER_UPDATE_SUCCESS = "SELLER_UPDATE_SUCCESS"
export const SELLER_UPDATE_FAIL = "SELLER_UPDATE_FAIL"
export const SELLER_UPDATE_RESET = "SELLER_UPDATE_RESET"

export const SELLER_DUPLICATE_CHECK_REQUEST = "SELLER_DUPLICATE_CHECK_REQUEST"
export const SELLER_DUPLICATE_CHECK_SUCCESS = "SELLER_DUPLICATE_CHECK_SUCCESS"
export const SELLER_DUPLICATE_CHECK_FAIL = "SELLER_DUPLICATE_CHECK_FAIL"
export const SELLER_DUPLICATE_CHECK_RESET = "SELLER_DUPLICATE_CHECK_RESET"

export const SELLER_TAGS_LIST_REQUEST = "SELLER_TAGS_LIST_REQUEST"
export const SELLER_TAGS_LIST_SUCCESS = "SELLER_TAGS_LIST_SUCCESS"
export const SELLER_TAGS_LIST_FAIL = "SELLER_TAGS_LIST_FAIL"
export const SELLER_TAGS_LIST_RESET = "SELLER_TAGS_LIST_RESET"

// delete seller

export const SELLER_DELETE_REQUEST = "SELLER_DELETE_REQUEST"
export const SELLER_DELETE_SUCCESS = "SELLER_DELETE_SUCCESS"
export const SELLER_DELETE_FAIL = "SELLER_DELETE_FAIL"
export const SELLER_DELETE_RESET = "SELLER_DELETE_RESET"
