export const NOTE_LIST_REQUEST = "NOTE_LIST_REQUEST"
export const NOTE_LIST_SUCCESS = "NOTE_LIST_SUCCESS"
export const NOTE_LIST_FAIL = "NOTE_LIST_FAIL"
export const NOTE_LIST_RESET = "NOTE_LIST_RESET"

export const NOTE_CREATE_REQUEST = "NOTE_CREATE_REQUEST"
export const NOTE_CREATE_SUCCESS = "NOTE_CREATE_SUCCESS"
export const NOTE_CREATE_FAIL = "NOTE_CREATE_FAIL"
export const NOTE_CREATE_RESET = "NOTE_CREATE_RESET"

export const NOTE_UPDATE_REQUEST = "NOTE_UPDATE_REQUEST"
export const NOTE_UPDATE_SUCCESS = "NOTE_UPDATE_SUCCESS"
export const NOTE_UPDATE_FAIL = "NOTE_UPDATE_FAIL"
export const NOTE_UPDATE_RESET = "NOTE_UPDATE_RESET"

export const NOTE_DELETE_REQUEST = "NOTE_DELETE_REQUEST"
export const NOTE_DELETE_SUCCESS = "NOTE_DELETE_SUCCESS"
export const NOTE_DELETE_FAIL = "NOTE_DELETE_FAIL"
export const NOTE_DELETE_RESET = "NOTE_DELETE_RESET"

export const TASK_LIST_REQUEST = "TASK_LIST_REQUEST"
export const TASK_LIST_SUCCESS = "TASK_LIST_SUCCESS"
export const TASK_LIST_FAIL = "TASK_LIST_FAIL"
export const TASK_LIST_RESET = "TASK_LIST_RESET"

export const TASK_CREATE_REQUEST = "TASK_CREATE_REQUEST"
export const TASK_CREATE_SUCCESS = "TASK_CREATE_SUCCESS"
export const TASK_CREATE_FAIL = "TASK_CREATE_FAIL"
export const TASK_CREATE_RESET = "TASK_CREATE_RESET"

export const TASK_UPDATE_REQUEST = "TASK_UPDATE_REQUEST"
export const TASK_UPDATE_SUCCESS = "TASK_UPDATE_SUCCESS"
export const TASK_UPDATE_FAIL = "TASK_UPDATE_FAIL"
export const TASK_UPDATE_RESET = "TASK_UPDATE_RESET"

export const TASK_DELETE_REQUEST = "TASK_DELETE_REQUEST"
export const TASK_DELETE_SUCCESS = "TASK_DELETE_SUCCESS"
export const TASK_DELETE_FAIL = "TASK_DELETE_FAIL"
export const TASK_DELETE_RESET = "TASK_DELETE_RESET"
