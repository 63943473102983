import {
  CAR_AUCTION_LIST_FAIL,
  CAR_AUCTION_LIST_REQUEST,
  CAR_AUCTION_LIST_RESET,
  CAR_AUCTION_LIST_SUCCESS,
  CAR_CREATE_FAIL,
  CAR_CREATE_REQUEST,
  CAR_CREATE_RESET,
  CAR_CREATE_SUCCESS,
  CAR_DELETE_FAIL,
  CAR_DELETE_REQUEST,
  CAR_DELETE_RESET,
  CAR_DELETE_SUCCESS,
  CAR_DETAIL_FAIL,
  CAR_DETAIL_REQUEST,
  CAR_DETAIL_RESET,
  CAR_DETAIL_SUCCESS,
  CAR_FILTER_OPTION_FAIL,
  CAR_FILTER_OPTION_REQUEST,
  CAR_FILTER_OPTION_RESET,
  CAR_FILTER_OPTION_SUCCESS,
  CAR_IMAGE_UPDATE_FAIL,
  CAR_IMAGE_UPDATE_REQUEST,
  CAR_IMAGE_UPDATE_RESET,
  CAR_IMAGE_UPDATE_SUCCESS,
  CAR_LIST_FAIL,
  CAR_LIST_REQUEST,
  CAR_LIST_SUCCESS,
  CAR_OPPORTUNITY_LIST_FAIL,
  CAR_OPPORTUNITY_LIST_REQUEST,
  CAR_OPPORTUNITY_LIST_SUCCESS,
  CAR_UPDATE_FAIL,
  CAR_UPDATE_REQUEST,
  CAR_UPDATE_RESET,
  CAR_UPDATE_SUCCESS,
} from "../constants/carMPConstants"

export const carListReducer = (state = { loading: true, cars: [] }, action) => {
  switch (action.type) {
    case CAR_LIST_REQUEST:
      return { loading: true, cars: [] }
    case CAR_LIST_SUCCESS:
      return {
        loading: false,
        cars: action.payload.cars,
        page: action.payload.page,
        pages: action.payload.pages,
        count: action.payload.count,
        staticCount: action.payload.staticCount,
      }
    case CAR_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const carUpdateReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case CAR_UPDATE_REQUEST:
      return { loading: true, success: false }
    case CAR_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        car: action.payload,
      }
    case CAR_UPDATE_RESET:
      return {
        loading: false,
        success: false,
      }
    case CAR_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const carFilterOptionReducer = (
  state = { options: [], loading: true },
  action
) => {
  switch (action.type) {
    case CAR_FILTER_OPTION_REQUEST:
      return { loading: true, options: [] }
    case CAR_FILTER_OPTION_SUCCESS:
      return { loading: false, options: action.payload }
    case CAR_FILTER_OPTION_FAIL:
      return { loading: false, error: action.payload }
    case CAR_FILTER_OPTION_RESET:
      return { loading: true, options: [] }
    default:
      return state
  }
}

export const carDetailReducer = (
  state = { car: {}, loading: true },
  action
) => {
  switch (action.type) {
    case CAR_DETAIL_REQUEST:
      return { loading: true, car: {} }
    case CAR_DETAIL_SUCCESS:
      return { loading: false, car: action.payload }
    case CAR_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    case CAR_DETAIL_RESET:
      return { loading: true, car: {} }
    default:
      return state
  }
}

export const carImageUpdateReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case CAR_IMAGE_UPDATE_REQUEST:
      return { loading: true, success: false }
    case CAR_IMAGE_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        car: action.payload,
      }
    case CAR_IMAGE_UPDATE_RESET:
      return {
        loading: false,
        success: false,
      }
    case CAR_IMAGE_UPDATE_FAIL:
      return { loading: false, success: false, error: action.payload }
    default:
      return state
  }
}

export const carOpportunityListReducer = (
  state = { loading: true, carOpportunities: [] },
  action
) => {
  switch (action.type) {
    case CAR_OPPORTUNITY_LIST_REQUEST:
      return { loading: true, carOpportunities: [] }
    case CAR_OPPORTUNITY_LIST_SUCCESS:
      return {
        loading: false,
        carOpportunities: action.payload,
      }
    case CAR_OPPORTUNITY_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const carCreateReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case CAR_CREATE_REQUEST:
      return { loading: true, success: false }
    case CAR_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        car: action.payload,
      }
    case CAR_CREATE_RESET:
      return {
        loading: false,
        success: false,
      }
    case CAR_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const carDeleteReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case CAR_DELETE_REQUEST:
      return { loading: true, success: false }
    case CAR_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case CAR_DELETE_RESET:
      return {
        loading: false,
        success: false,
      }
    case CAR_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const carAuctionListReducer = (
  state = { loading: false, carAuctionList: [] },
  action
) => {
  switch (action.type) {
    case CAR_AUCTION_LIST_REQUEST:
      return { loading: true, carAuctionList: [] }
    case CAR_AUCTION_LIST_SUCCESS:
      return {
        loading: false,
        carAuctionList: action.payload.cars,
        count: action.payload.count,
      }
    case CAR_AUCTION_LIST_RESET:
      return {
        loading: false,
        carAuctionList: {},
      }
    case CAR_AUCTION_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
