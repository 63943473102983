export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST"
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS"
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL"
export const USER_LOGOUT = "USER_LOGOUT"

export const USER_CREATE_REQUEST = "USER_CREATE_REQUEST"
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS"
export const USER_CREATE_FAIL = "USER_CREATE_FAIL"
export const USER_CREATE_RESET = "USER_CREATE_RESET"

export const USER_LIST_REQUEST = "USER_LIST_REQUEST"
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS"
export const USER_LIST_FAIL = "USER_LIST_FAIL"
export const USER_LIST_RESET = "USER_LIST_RESET"

export const USER_DETAIL_REQUEST = "USER_DETAIL_REQUEST"
export const USER_DETAIL_SUCCESS = "USER_DETAIL_SUCCESS"
export const USER_DETAIL_FAIL = "USER_DETAIL_FAIL"
export const USER_DETAIL_RESET = "USER_DETAIL_RESET"

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST"
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS"
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL"
export const USER_UPDATE_RESET = "USER_UPDATE_RESET"

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST"
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS"
export const USER_DELETE_FAIL = "USER_DELETE_FAIL"
export const USER_DELETE_RESET = "USER_DELETE_RESET"
