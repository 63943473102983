import {
  MODEL_CREATE_FAIL,
  MODEL_CREATE_REQUEST,
  MODEL_CREATE_RESET,
  MODEL_CREATE_SUCCESS,
  MODEL_DELETE_FAIL,
  MODEL_DELETE_REQUEST,
  MODEL_DELETE_SUCCESS,
  MODEL_DETAIL_FAIL,
  MODEL_DETAIL_LIST_FAIL,
  MODEL_DETAIL_LIST_REQUEST,
  MODEL_DETAIL_LIST_SUCCESS,
  MODEL_DETAIL_REQUEST,
  MODEL_DETAIL_SUCCESS,
  MODEL_LIST_FAIL,
  MODEL_LIST_REQUEST,
  MODEL_LIST_REVIEW_FAIL,
  MODEL_LIST_REVIEW_REQUEST,
  MODEL_LIST_REVIEW_SUCCESS,
  MODEL_LIST_SUCCESS,
  MODEL_UPDATE_FAIL,
  MODEL_UPDATE_REQUEST,
  MODEL_UPDATE_RESET,
  MODEL_UPDATE_SUCCESS,
} from "../constants/modelConstants"

export const modelListReducer = (
  state = { models: [], loading: true },
  action
) => {
  switch (action.type) {
    case MODEL_LIST_REQUEST:
      return { loading: true, models: [] }
    case MODEL_LIST_SUCCESS:
      return {
        loading: false,
        models: action.payload.models,
        page: action.payload.page,
        pages: action.payload.pages,
      }
    case MODEL_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const modelListReviewReducer = (
  state = { models: [], loading: true },
  action
) => {
  switch (action.type) {
    case MODEL_LIST_REVIEW_REQUEST:
      return { loading: true, models: [] }
    case MODEL_LIST_REVIEW_SUCCESS:
      return {
        loading: false,
        models: action.payload.models,
      }
    case MODEL_LIST_REVIEW_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const modelDetailListReducer = (
  state = { models: [], loading: true },
  action
) => {
  switch (action.type) {
    case MODEL_DETAIL_LIST_REQUEST:
      return { loading: true, models: [] }
    case MODEL_DETAIL_LIST_SUCCESS:
      return {
        loading: false,
        modelDetailList: action.payload.modelDetailList,
        page: action.payload.page,
        pages: action.payload.pages,
      }
    case MODEL_DETAIL_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const modelCreateReducer = (
  state = { success: false, loading: true },
  action
) => {
  switch (action.type) {
    case MODEL_CREATE_REQUEST:
      return { loading: true, success: false }
    case MODEL_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case MODEL_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case MODEL_CREATE_RESET:
      return {
        loading: true,
        success: false,
      }
    default:
      return state
  }
}

export const modelDetailReducer = (
  state = { model: {}, loading: true },
  action
) => {
  switch (action.type) {
    case MODEL_DETAIL_REQUEST:
      return { loading: true, model: {} }
    case MODEL_DETAIL_SUCCESS:
      return {
        loading: false,
        model: action.payload,
      }
    case MODEL_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const modelUpdateReducer = (
  state = { success: false, loading: true },
  action
) => {
  switch (action.type) {
    case MODEL_UPDATE_REQUEST:
      return { loading: true, success: false }
    case MODEL_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case MODEL_UPDATE_RESET:
      return {
        loading: true,
        success: false,
      }
    case MODEL_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const modelDeleteReducer = (
  state = { success: false, loading: true },
  action
) => {
  switch (action.type) {
    case MODEL_DELETE_REQUEST:
      return { loading: true, success: false }
    case MODEL_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case MODEL_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
