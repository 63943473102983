import {
  TAG_LIST_FAIL,
  TAG_LIST_REQUEST,
  TAG_LIST_RESET,
  TAG_LIST_SUCCESS,
} from "../constants/tagsContants"

export const tagsListReducer = (
  state = { tags: [], loading: true },
  action
) => {
  switch (action.type) {
    case TAG_LIST_REQUEST:
      return { loading: true, tags: [] }
    case TAG_LIST_SUCCESS:
      return {
        loading: false,
        tags: action.payload,
      }
    case TAG_LIST_RESET:
      return {
        loading: true,
        tags: [],
      }
    case TAG_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
