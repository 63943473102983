import { useSelector } from "react-redux"

const useAuthUser = () => {
  const userLogin = useSelector((state) => state.users.userLogin)
  const { userInfo } = userLogin

  return [userInfo]
}

export default useAuthUser
