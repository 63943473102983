import React, { useEffect, useRef, useState } from "react"
import Axios from "../../utils/axios"
import { useSelector, useDispatch } from "react-redux"
import { changeNotifications } from "../../state/actions/notificationsAction"
import {
  NotificationDropdown,
  NotificationDropdownDesktop,
} from "./NotificationDropdown"
import bell from "../../images/header/bell.svg"

import { useMediaQuery } from "@mui/material"
import useAuthUser from "../../state/customHooks/user/useAuthUser"

const NotificationBell = () => {
  const dispatch = useDispatch()
  const [notificationCount, setNotificationCount] = useState(0)
  const [showDropdown, setShowDropdown] = useState(false)
  const dropdownRef = useRef(null)
  const isMobile = useMediaQuery("(max-width: 600px)")

  const [user] = useAuthUser()

  const notifications = useSelector(
    (state) => state.notifications.notificationsChange
  )
  const { notify } = notifications

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
  }

  // Fetch notifications from API and update Redux store
  const fetchNotifications = async () => {
    try {
      const { data } = await Axios.get(
        user._officialBrand
          ? `/v1/officialBrands/notification`
          : `/v1/notification`,
        config
      )
      dispatch(changeNotifications(data))
    } catch (error) {
      console.error("Error fetching notifications", error)
    }
  }

  useEffect(() => {
    fetchNotifications()
  }, [])

  // Calculate unread notifications count
  const calculateUnreadCount = (notifications) => {
    return notifications.filter((n) => !n.isRead).length
  }

  useEffect(() => {
    if (notify && notify.length > 0) {
      setNotificationCount(calculateUnreadCount(notify))
    }
  }, [notify])

  const handleBellClick = () => {
    setShowDropdown(!showDropdown)
  }

  // Mark all notifications as read
  const handleMarkAllAsRead = async () => {
    try {
      if (user._admin) {
        console.log(config)
        await Axios.patch("/v1/notification/markAllAsRead", {}, config)
      } else if (user._officialBrand) {
        await Axios.patch(
          "/v1/officialBrands/notification/markAllAsRead",
          {},
          config
        )
      } else {
      }

      fetchNotifications()
    } catch (error) {
      console.error("Failed to mark all notifications as read", error)
    }
  }

  // Mark a single notification as read and open its link
  const handleNotificationClick = async (notification) => {
    try {
      if (user._admin) {
        await Axios.patch(
          `/v1/notification/${notification._id}/read`,
          {},
          config
        )
      } else if (user._officialBrand) {
        await Axios.patch(
          `/v1/officialBrands/notification/${notification._id}/read`,
          {},
          config
        )
      }
      fetchNotifications()
    } catch (error) {
      console.error("Failed to mark notification as read", error)
    }
    window.open(notification.link, "_blank", "noopener,noreferrer")
  }

  // Close dropdown if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <div className="notification-bell-container">
      <div className="notification-bell" onClick={handleBellClick}>
        <img src={bell} alt="Notifications" />
        {notificationCount > 0 && (
          <span className="notification-badge">{notificationCount}</span>
        )}
        {!isMobile && showDropdown && (
          <NotificationDropdownDesktop
            showDropdown={showDropdown}
            setShowDropdown={setShowDropdown}
            notify={notify}
            handleNotificationClick={handleNotificationClick}
            handleMarkAllAsRead={handleMarkAllAsRead}
          />
        )}
      </div>

      {isMobile && showDropdown && (
        <NotificationDropdown
          notify={notify}
          handleMarkAllAsRead={handleMarkAllAsRead}
          handleNotificationClick={handleNotificationClick}
          setShowDropdown={setShowDropdown}
          showDropdown={showDropdown}
        />
      )}
    </div>
  )
}

export default NotificationBell
