import { RESPONSE_CREATE, RESPONSE_RESET } from "../constants/responseConstants"

export const responseReducer = (
  state = { message: "", loading: false },
  action
) => {
  switch (action.type) {
    case RESPONSE_CREATE:
      return { message: action.payload }

    case RESPONSE_RESET:
      return { message: "" }
    default:
      return state
  }
}
