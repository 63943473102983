export const CAR_LIST_REQUEST = "CAR_LIST_REQUEST"
export const CAR_LIST_SUCCESS = "CAR_LIST_SUCCESS"
export const CAR_LIST_FAIL = "CAR_LIST_FAIL"

export const CAR_UPDATE_REQUEST = "CAR_UPDATE_REQUEST"
export const CAR_UPDATE_SUCCESS = "CAR_UPDATE_SUCCESS"
export const CAR_UPDATE_FAIL = "CAR_UPDATE_FAIL"
export const CAR_UPDATE_RESET = "CAR_UPDATE_RESET"

export const CAR_DELETE_REQUEST = "CAR_DELETE_REQUEST"
export const CAR_DELETE_SUCCESS = "CAR_DELETE_SUCCESS"
export const CAR_DELETE_FAIL = "CAR_DELETE_FAIL"
export const CAR_DELETE_RESET = "CAR_DELETE_RESET"

export const CAR_FILTER_OPTION_REQUEST = "CAR_FILTER_OPTION_REQUEST"
export const CAR_FILTER_OPTION_SUCCESS = "CAR_FILTER_OPTION_SUCCESS"
export const CAR_FILTER_OPTION_FAIL = "CAR_FILTER_OPTION_FAIL"
export const CAR_FILTER_OPTION_RESET = "CAR_FILTER_OPTION_RESET"

export const CAR_DETAIL_REQUEST = "CAR_DETAIL_REQUEST"
export const CAR_DETAIL_SUCCESS = "CAR_DETAIL_SUCCESS"
export const CAR_DETAIL_FAIL = "CAR_DETAIL_FAIL"
export const CAR_DETAIL_RESET = "CAR_DETAIL_RESET"

export const CAR_IMAGE_UPDATE_REQUEST = "CAR_IMAGE_UPDATE_REQUEST"
export const CAR_IMAGE_UPDATE_SUCCESS = "CAR_IMAGE_UPDATE_SUCCESS"
export const CAR_IMAGE_UPDATE_FAIL = "CAR_IMAGE_UPDATE_FAIL"
export const CAR_IMAGE_UPDATE_RESET = "CAR_IMAGE_UPDATE_RESET"

export const CAR_OPPORTUNITY_LIST_REQUEST = "CAR_OPPORTUNITY_LIST_REQUEST"
export const CAR_OPPORTUNITY_LIST_SUCCESS = "CAR_OPPORTUNITY_LIST_SUCCESS"
export const CAR_OPPORTUNITY_LIST_FAIL = "CAR_OPPORTUNITY_LIST_FAIL"

export const CAR_CREATE_REQUEST = "CAR_CREATE_REQUEST"
export const CAR_CREATE_SUCCESS = "CAR_CREATE_SUCCESS"
export const CAR_CREATE_FAIL = "CAR_CREATE_FAIL"
export const CAR_CREATE_RESET = "CAR_CREATE_RESET"

export const CAR_AUCTION_LIST_REQUEST = "CAR_AUCTION_LIST_REQUEST"
export const CAR_AUCTION_LIST_SUCCESS = "CAR_AUCTION_LIST_SUCCESS"
export const CAR_AUCTION_LIST_FAIL = "CAR_AUCTION_LIST_FAIL"
export const CAR_AUCTION_LIST_RESET = "CAR_AUCTION_LIST_RESET"
