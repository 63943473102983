import {
  REVIEW_CREATE_FAIL,
  REVIEW_CREATE_REQUEST,
  REVIEW_CREATE_RESET,
  REVIEW_CREATE_SUCCESS,
  REVIEW_DETAIL_FAIL,
  REVIEW_DETAIL_REQUEST,
  REVIEW_DETAIL_SUCCESS,
  REVIEW_UPDATE_FAIL,
  REVIEW_UPDATE_REQUEST,
  REVIEW_UPDATE_RESET,
  REVIEW_UPDATE_SUCCESS,
} from "../constants/reviewConstants"

export const reviewCreateReducer = (
  state = { loading: false, success: false, review: {} },
  action
) => {
  switch (action.type) {
    case REVIEW_CREATE_REQUEST:
      return { loading: true, success: false, reivew: {} }
    case REVIEW_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        review: action.payload,
      }
    case REVIEW_CREATE_RESET:
      return {
        loading: false,
        success: false,
      }
    case REVIEW_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const reviewDetailReducer = (
  state = { review: {}, loading: true },
  action
) => {
  switch (action.type) {
    case REVIEW_DETAIL_REQUEST:
      return { loading: true, review: {} }
    case REVIEW_DETAIL_SUCCESS:
      return {
        loading: false,
        review: action.payload,
      }
    case REVIEW_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const reviewUpdateReducer = (
  state = { loading: false, success: false, review: {} },
  action
) => {
  switch (action.type) {
    case REVIEW_UPDATE_REQUEST:
      return { loading: true, success: false, reivew: {} }
    case REVIEW_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        review: action.payload,
      }
    case REVIEW_UPDATE_RESET:
      return {
        loading: false,
        success: false,
      }
    case REVIEW_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
