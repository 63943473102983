import { Navigate } from "react-router-dom"
import {
  hasPermission,
  hasResourcePermission,
} from "../../utils/utilsFunctions"

export const ProtectedRoute = ({ permission, resource, userInfo, element }) => {
  return hasResourcePermission(userInfo, resource) &&
    hasPermission(userInfo, resource, permission) ? (
    element
  ) : (
    <Navigate to="/unauthorized" />
  )
}
