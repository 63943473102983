import {
  AUCTION_CREATE_FAIL,
  AUCTION_CREATE_REQUEST,
  AUCTION_CREATE_RESET,
  AUCTION_CREATE_SUCCESS,
  AUCTION_DELETE_FAIL,
  AUCTION_DELETE_REQUEST,
  AUCTION_DELETE_RESET,
  AUCTION_DELETE_SUCCESS,
  AUCTION_DETAIL_FAIL,
  AUCTION_DETAIL_REQUEST,
  AUCTION_DETAIL_RESET,
  AUCTION_DETAIL_SUCCESS,
  AUCTION_LIST_FAIL,
  AUCTION_LIST_REQUEST,
  AUCTION_LIST_RESET,
  AUCTION_LIST_SUCCESS,
  AUCTION_UPDATE_FAIL,
  AUCTION_UPDATE_REQUEST,
  AUCTION_UPDATE_RESET,
  AUCTION_UPDATE_SUCCESS,
} from "../constants/auctionConstants"

export const auctionListReducer = (
  state = { loading: true, auctions: [], counts: {} },
  action
) => {
  switch (action.type) {
    case AUCTION_LIST_REQUEST:
      return { loading: true, auctions: [], counts: {} }
    case AUCTION_LIST_SUCCESS:
      return {
        loading: false,
        auctions: action.payload.auctions,
        counts: action.payload.staticCount,
      }
    case AUCTION_LIST_FAIL:
      return { loading: false, error: action.payload }
    case AUCTION_LIST_RESET:
      return { auctions: [] }
    default:
      return state
  }
}

export const auctionCreateReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case AUCTION_CREATE_REQUEST:
      return { loading: true, success: false }
    case AUCTION_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case AUCTION_CREATE_FAIL:
      return { loading: false, error: action.payload, success: false }
    case AUCTION_CREATE_RESET:
      return { loading: false, success: false }
    default:
      return state
  }
}

export const auctionDetailReducer = (
  state = { loading: true, auction: {} },
  action
) => {
  switch (action.type) {
    case AUCTION_DETAIL_REQUEST:
      return { loading: true, auction: {} }
    case AUCTION_DETAIL_SUCCESS:
      return {
        loading: false,
        auction: action.payload,
      }
    case AUCTION_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    case AUCTION_DETAIL_RESET:
      return { loading: true, auction: {} }
    default:
      return state
  }
}

export const auctionUpdateReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case AUCTION_UPDATE_REQUEST:
      return { loading: true, success: false }
    case AUCTION_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        auction: action.payload,
      }
    case AUCTION_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case AUCTION_UPDATE_RESET:
      return { loading: false, success: false }
    default:
      return state
  }
}

export const auctionDeleteReducer = (
  state = { loading: true, success: false },
  action
) => {
  switch (action.type) {
    case AUCTION_DELETE_REQUEST:
      return { loading: true, success: false }
    case AUCTION_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case AUCTION_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case AUCTION_DELETE_RESET:
      return { loading: true, success: false }
    default:
      return state
  }
}
