import {
  YEAR_SPEC_CREATE_FAIL,
  YEAR_SPEC_CREATE_REQUEST,
  YEAR_SPEC_CREATE_RESET,
  YEAR_SPEC_CREATE_SUCCESS,
  YEAR_SPEC_DETAIL_FAIL,
  YEAR_SPEC_DETAIL_REQUEST,
  YEAR_SPEC_DETAIL_RESET,
  YEAR_SPEC_DETAIL_SUCCESS,
  YEAR_SPEC_LIST_FAIL,
  YEAR_SPEC_LIST_REQUEST,
  YEAR_SPEC_LIST_RESET,
  YEAR_SPEC_LIST_SUCCESS,
  YEAR_SPEC_UPDATE_FAIL,
  YEAR_SPEC_UPDATE_REQUEST,
  YEAR_SPEC_UPDATE_RESET,
  YEAR_SPEC_UPDATE_SUCCESS,
} from "../constants/carDatabase"

export const yearSpecTemplateCreateReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case YEAR_SPEC_CREATE_REQUEST:
      return { loading: true, success: false }
    case YEAR_SPEC_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        year: action.payload,
      }
    case YEAR_SPEC_CREATE_FAIL:
      return { loading: false, error: action.payload, success: false }
    case YEAR_SPEC_CREATE_RESET:
      return { loading: false, success: false }
    default:
      return state
  }
}
export const yearSpecTemplateListReducer = (
  state = { loading: false, years: [] },
  action
) => {
  switch (action.type) {
    case YEAR_SPEC_LIST_REQUEST:
      return { loading: true, years: [] }
    case YEAR_SPEC_LIST_SUCCESS:
      return {
        loading: false,
        years: action.payload,
      }
    case YEAR_SPEC_LIST_FAIL:
      return { loading: false, error: action.payload }
    case YEAR_SPEC_LIST_RESET:
      return { loading: false, years: [] }
    default:
      return state
  }
}

export const yearSpecTemplateDetailReducer = (
  state = { loading: false, year: {} },
  action
) => {
  switch (action.type) {
    case YEAR_SPEC_DETAIL_REQUEST:
      return { loading: true, year: {} }
    case YEAR_SPEC_DETAIL_SUCCESS:
      return {
        loading: false,
        year: action.payload,
      }
    case YEAR_SPEC_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    case YEAR_SPEC_DETAIL_RESET:
      return { loading: false, year: {} }
    default:
      return state
  }
}

export const yearSpecTemplateUpdateReducer = (
  state = { loading: false, success: false, year: {} },
  action
) => {
  switch (action.type) {
    case YEAR_SPEC_UPDATE_REQUEST:
      return { loading: true, success: false, year: {} }
    case YEAR_SPEC_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        year: action.payload,
      }
    case YEAR_SPEC_UPDATE_FAIL:
      return { loading: false, error: action.payload, success: false }
    case YEAR_SPEC_UPDATE_RESET:
      return { loading: false, success: false, year: {} }
    default:
      return state
  }
}
