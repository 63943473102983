import {
  YEAR_BY_MODEL_GENERATION_LIST_FAIL,
  YEAR_BY_MODEL_GENERATION_LIST_REQUEST,
  YEAR_BY_MODEL_GENERATION_LIST_SUCCESS,
  YEAR_CREATE_FAIL,
  YEAR_CREATE_RANGE_FAIL,
  YEAR_CREATE_RANGE_REQUEST,
  YEAR_CREATE_RANGE_RESET,
  YEAR_CREATE_RANGE_SUCCESS,
  YEAR_CREATE_REQUEST,
  YEAR_CREATE_RESET,
  YEAR_CREATE_SUCCESS,
  YEAR_DELETE_FAIL,
  YEAR_DELETE_REQUEST,
  YEAR_DELETE_RESET,
  YEAR_DELETE_SUCCESS,
  YEAR_DETAIL_FAIL,
  YEAR_DETAIL_REQUEST,
  YEAR_DETAIL_RESET,
  YEAR_DETAIL_SUCCESS,
  YEAR_LIST_FAIL,
  YEAR_LIST_REQUEST,
  YEAR_LIST_RESET,
  YEAR_LIST_REVIEW_FAIL,
  YEAR_LIST_REVIEW_REQUEST,
  YEAR_LIST_REVIEW_RESET,
  YEAR_LIST_SUCCESS,
  YEAR_UPDATE_FAIL,
  YEAR_UPDATE_REQUEST,
  YEAR_UPDATE_RESET,
  YEAR_UPDATE_SUCCESS,
} from "../constants/yearConstants"

export const yearListReducer = (
  state = { years: [], loading: true },
  action
) => {
  switch (action.type) {
    case YEAR_LIST_REQUEST:
      return { loading: true, years: [] }
    case YEAR_LIST_SUCCESS:
      return {
        loading: false,
        years: action.payload.years,
      }
    case YEAR_LIST_RESET:
      return {
        loading: true,
        years: [],
      }
    case YEAR_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const yearListReviewReducer = (
  state = { years: [], loading: true },
  action
) => {
  switch (action.type) {
    case YEAR_LIST_REVIEW_REQUEST:
      return { loading: true, years: [] }
    case YEAR_LIST_SUCCESS:
      return {
        loading: false,
        years: action.payload.years,
      }
    case YEAR_LIST_REVIEW_RESET:
      return {
        loading: true,
        years: [],
      }
    case YEAR_LIST_REVIEW_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const yearByModelGenerationListReducer = (
  state = { years: [], loading: true },
  action
) => {
  switch (action.type) {
    case YEAR_BY_MODEL_GENERATION_LIST_REQUEST:
      return { loading: true, years: [] }
    case YEAR_BY_MODEL_GENERATION_LIST_SUCCESS:
      return {
        loading: false,
        years: action.payload,
      }
    case YEAR_BY_MODEL_GENERATION_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const yearDeleteReducer = (
  state = { success: false, loading: true },
  action
) => {
  switch (action.type) {
    case YEAR_DELETE_REQUEST:
      return { loading: true, success: false }
    case YEAR_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case YEAR_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case YEAR_DELETE_RESET:
      return {
        loading: true,
        success: false,
      }
    default:
      return state
  }
}

export const yearCreateReducer = (
  state = { success: false, loading: false, year: {} },
  action
) => {
  switch (action.type) {
    case YEAR_CREATE_REQUEST:
      return { loading: true, success: false, year: {} }
    case YEAR_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        year: action.payload,
      }
    case YEAR_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case YEAR_CREATE_RESET:
      return {
        loading: false,
        success: false,
      }
    default:
      return state
  }
}
export const yearRangeCreateReducer = (
  state = { success: false, loading: false },
  action
) => {
  switch (action.type) {
    case YEAR_CREATE_RANGE_REQUEST:
      return { loading: true, success: false }
    case YEAR_CREATE_RANGE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case YEAR_CREATE_RANGE_FAIL:
      return { loading: false, error: action.payload }
    case YEAR_CREATE_RANGE_RESET:
      return {
        loading: false,
        success: false,
      }
    default:
      return state
  }
}

export const yearUpdateReducer = (
  state = { success: false, loading: false },
  action
) => {
  switch (action.type) {
    case YEAR_UPDATE_REQUEST:
      return { loading: true, success: false }
    case YEAR_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        year: action.payload,
      }
    case YEAR_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case YEAR_UPDATE_RESET:
      return {
        loading: false,
        success: false,
      }
    default:
      return state
  }
}

export const yearDetailReducer = (
  state = { year: {}, loading: true },
  action
) => {
  switch (action.type) {
    case YEAR_DETAIL_REQUEST:
      return { loading: true, year: {} }
    case YEAR_DETAIL_SUCCESS:
      return {
        loading: false,
        year: action.payload,
      }
    case YEAR_DETAIL_RESET:
      return {
        loading: true,
        year: {},
      }
    case YEAR_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
