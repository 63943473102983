import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import "./index.scss"
import App from "./App"
import { store } from "./state/store"
import { BrowserRouter } from "react-router-dom"
import { ThemeProvider, createTheme } from "@mui/material/styles"

const theme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "green" },
          style: {
            textTransform: "none",
            background: "#12A01C",
            borderRadius: 0,
            color: "#fff",
            fontSize: "14px",
            lineHeight: "16px",
            "&:hover": {
              background: "#12A01C",
            },
          },
        },
      ],
    },
  },
  palette: {
    primary: {
      main: "#1C86B8",
    },
    secondary: {
      main: "#000000",
    },
    pale: {
      main: "#F7F7F7",
    },
    white: {
      main: "#FFFFFF",
    },
  },
})

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
)
