export const REVIEW_CREATE_REQUEST = "REVIEW_CREATE_REQUEST"
export const REVIEW_CREATE_SUCCESS = "REVIEW_CREATE_SUCCESS"
export const REVIEW_CREATE_FAIL = "REVIEW_CREATE_FAIL"
export const REVIEW_CREATE_RESET = "REVIEW_CREATE_RESET"

export const REVIEW_UPDATE_REQUEST = "REVIEW_UPDATE_REQUEST"
export const REVIEW_UPDATE_SUCCESS = "REVIEW_UPDATE_SUCCESS"
export const REVIEW_UPDATE_FAIL = "REVIEW_UPDATE_FAIL"
export const REVIEW_UPDATE_RESET = "REVIEW_UPDATE_RESET"

export const REVIEW_DETAIL_REQUEST = "REVIEW_DETAIL_REQUEST"
export const REVIEW_DETAIL_SUCCESS = "REVIEW_DETAIL_SUCCESS"
export const REVIEW_DETAIL_FAIL = "REVIEW_DETAIL_FAIL"
