import { NOTIFICATION_CHANGE } from "../constants/notificationConstants"

export const notificationsChangeReducer = (state = { notify: [] }, action) => {
  switch (action.type) {
    case NOTIFICATION_CHANGE:
      return { notify: action.payload }

    default:
      return state
  }
}
