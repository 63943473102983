import {
  SELLER_CREATE_FAIL,
  SELLER_CREATE_REQUEST,
  SELLER_CREATE_RESET,
  SELLER_CREATE_SUCCESS,
  SELLER_DELETE_FAIL,
  SELLER_DELETE_REQUEST,
  SELLER_DELETE_RESET,
  SELLER_DELETE_SUCCESS,
  SELLER_DETAIL_FAIL,
  SELLER_DETAIL_REQUEST,
  SELLER_DETAIL_RESET,
  SELLER_DETAIL_SUCCESS,
  SELLER_DUPLICATE_CHECK_FAIL,
  SELLER_DUPLICATE_CHECK_REQUEST,
  SELLER_DUPLICATE_CHECK_RESET,
  SELLER_DUPLICATE_CHECK_SUCCESS,
  SELLER_LIST_FAIL,
  SELLER_LIST_REQUEST,
  SELLER_LIST_RESET,
  SELLER_LIST_SUCCESS,
  SELLER_TAGS_LIST_FAIL,
  SELLER_TAGS_LIST_REQUEST,
  SELLER_TAGS_LIST_SUCCESS,
  SELLER_UPDATE_FAIL,
  SELLER_UPDATE_REQUEST,
  SELLER_UPDATE_RESET,
  SELLER_UPDATE_SUCCESS,
} from "../constants/sellerContants"

export const sellerListReducer = (
  state = { sellers: [], allSellers: [], loading: true },
  action
) => {
  switch (action.type) {
    case SELLER_LIST_REQUEST:
      return { loading: true, sellers: [], allSellers: [] }
    case SELLER_LIST_SUCCESS:
      return {
        loading: false,
        sellers: action.payload.sellers,
        allSellers: action.payload.allSellers,
        count: action.payload.count,
        page: action.payload.page,
        pages: action.payload.pages,
      }
    case SELLER_LIST_RESET:
      return {
        loading: false,
        sellers: [],
      }
    case SELLER_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const sellerDetailReducer = (
  state = { seller: {}, loading: true, users: [], parentUsers: [] },
  action
) => {
  switch (action.type) {
    case SELLER_DETAIL_REQUEST:
      return { loading: true, seller: {}, users: [], parentUsers: [] }
    case SELLER_DETAIL_SUCCESS:
      return {
        loading: false,
        seller: action.payload.seller,
        users: action.payload.users,
        parentUsers: action.payload.parentUsers,
        analytics: action.payload.analytics,
      }
    case SELLER_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    case SELLER_DETAIL_RESET:
      return { loading: true, seller: {}, users: [] }
    default:
      return state
  }
}

export const sellerUpdateReducer = (
  state = { loading: true, success: false, seller: {} },
  action
) => {
  switch (action.type) {
    case SELLER_UPDATE_REQUEST:
      return { loading: true, success: false, seller: {} }
    case SELLER_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        seller: action.payload,
      }
    case SELLER_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case SELLER_UPDATE_RESET:
      return {
        loading: true,
        success: false,
      }

    default:
      return state
  }
}

export const sellerCreateReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case SELLER_CREATE_REQUEST:
      return { loading: true, success: false }
    case SELLER_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case SELLER_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case SELLER_CREATE_RESET:
      return { loading: false, success: false }
    default:
      return state
  }
}

export const sellerDuplicateCheckReducer = (
  state = { sellers: [], loading: true },
  action
) => {
  switch (action.type) {
    case SELLER_DUPLICATE_CHECK_REQUEST:
      return { loading: true, sellers: [] }
    case SELLER_DUPLICATE_CHECK_SUCCESS:
      return {
        loading: false,
        sellers: action.payload.sellers,
      }
    case SELLER_DUPLICATE_CHECK_FAIL:
      return { loading: false, error: action.payload }
    case SELLER_DUPLICATE_CHECK_RESET:
      return { SELLERs: [], loading: true }
    default:
      return state
  }
}

export const sellerTagsListReducer = (
  state = { tags: [], loading: true },
  action
) => {
  switch (action.type) {
    case SELLER_TAGS_LIST_REQUEST:
      return { loading: true, tags: [] }
    case SELLER_TAGS_LIST_SUCCESS:
      return {
        loading: false,
        tags: action.payload,
      }
    case SELLER_TAGS_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

// seller delete reducer

export const sellerDeleteReducer = (
  state = { loading: true, success: false },
  action
) => {
  switch (action.type) {
    case SELLER_DELETE_REQUEST:
      return { loading: true, success: false }
    case SELLER_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case SELLER_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case SELLER_DELETE_RESET:
      return { loading: true, success: false }
    default:
      return state
  }
}
