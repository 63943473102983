export const SALE_LIST_REQUEST = "SALE_LIST_REQUEST"
export const SALE_LIST_SUCCESS = "SALE_LIST_SUCCESS"
export const SALE_LIST_FAIL = "SALE_LIST_FAIL"
export const SALE_LIST_RESET = "SALE_LIST_RESET"

export const SALE_CREATE_REQUEST = "SALE_CREATE_REQUEST"
export const SALE_CREATE_SUCCESS = "SALE_CREATE_SUCCESS"
export const SALE_CREATE_FAIL = "SALE_CREATE_FAIL"
export const SALE_CREATE_RESET = "SALE_CREATE_RESET"

export const SALE_DETAIL_REQUEST = "SALE_DETAIL_REQUEST"
export const SALE_DETAIL_SUCCESS = "SALE_DETAIL_SUCCESS"
export const SALE_DETAIL_FAIL = "SALE_DETAIL_FAIL"
export const SALE_DETAIL_RESET = "SALE_DETAIL_RESET"

export const SALE_UPDATE_REQUEST = "SALE_UPDATE_REQUEST"
export const SALE_UPDATE_SUCCESS = "SALE_UPDATE_SUCCESS"
export const SALE_UPDATE_FAIL = "SALE_UPDATE_FAIL"
export const SALE_UPDATE_RESET = "SALE_UPDATE_RESET"

export const SALE_DELETE_REQUEST = "SALE_DELETE_REQUEST"
export const SALE_DELETE_SUCCESS = "SALE_DELETE_SUCCESS"
export const SALE_DELETE_FAIL = "SALE_DELETE_FAIL"
export const SALE_DELETE_RESET = "SALE_DELETE_RESET"
