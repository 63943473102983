export const AUCTION_LIST_REQUEST = "AUCTION_LIST_REQUEST"
export const AUCTION_LIST_SUCCESS = "AUCTION_LIST_SUCCESS"
export const AUCTION_LIST_FAIL = "AUCTION_LIST_FAIL"
export const AUCTION_LIST_RESET = "AUCTION_LIST_RESET"

export const AUCTION_CREATE_REQUEST = "AUCTION_CREATE_REQUEST"
export const AUCTION_CREATE_SUCCESS = "AUCTION_CREATE_SUCCESS"
export const AUCTION_CREATE_FAIL = "AUCTION_CREATE_FAIL"
export const AUCTION_CREATE_RESET = "AUCTION_CREATE_RESET"

export const AUCTION_DETAIL_REQUEST = "AUCTION_DETAIL_REQUEST"
export const AUCTION_DETAIL_SUCCESS = "AUCTION_DETAIL_SUCCESS"
export const AUCTION_DETAIL_FAIL = "AUCTION_DETAIL_FAIL"
export const AUCTION_DETAIL_RESET = "AUCTION_DETAIL_RESET"

export const AUCTION_UPDATE_REQUEST = "AUCTION_UPDATE_REQUEST"
export const AUCTION_UPDATE_SUCCESS = "AUCTION_UPDATE_SUCCESS"
export const AUCTION_UPDATE_FAIL = "AUCTION_UPDATE_FAIL"
export const AUCTION_UPDATE_RESET = "AUCTION_UPDATE_RESET"

export const AUCTION_DELETE_REQUEST = "AUCTION_DELETE_REQUEST"
export const AUCTION_DELETE_SUCCESS = "AUCTION_DELETE_SUCCESS"
export const AUCTION_DELETE_FAIL = "AUCTION_DELETE_FAIL"
export const AUCTION_DELETE_RESET = "AUCTION_DELETE_RESET"
