import _ from "lodash"

export const generateQueryURL = (queryObject) => {
  const queryString = new URLSearchParams(queryObject)
  return queryString
}

export const setYear = (years) => {
  years.sort()

  var inProcessYears = []

  var managedYears = []

  for (let i = 0; i < years.length; i++) {
    if (years[i] + 1 === years[i + 1] && years[i] - 1 !== years[i - 1]) {
      inProcessYears.push(years[i])
    } else if (years[i] - 1 === years[i - 1] && years[i] + 1 !== years[i + 1]) {
      inProcessYears.push(years[i])
    } else if (years[i] - 1 === years[i - 1] && years[i] + 1 === years[i + 1]) {
    } else if (years[i] - 1 !== years[i - 1] && years[i] + 1 !== years[i + 1]) {
      managedYears.push(years[i])
    }
  }

  for (let i = 0; i < inProcessYears.length; i += 2) {
    managedYears.push(inProcessYears[i] + "-" + inProcessYears[i + 1])
  }

  return managedYears.sort()
}

export const numberWithCommas = (x) => {
  return `${x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
}
export const currencyWithCommas = (x) => {
  return `$${x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
}
export const transformComa = (x) => {
  return `${x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
}

export function toCamelCase(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, "")
}

export function fromCamelCase(str) {
  return _.startCase(str)
}

export const updateArrayState = (updatedObject, setStateFunc) => {
  setStateFunc((prevArray) => {
    return prevArray.map((item) =>
      item._id === updatedObject._id ? updatedObject : item
    )
  })
}

export function extractNumber(response) {
  const regex = /\d+/
  const matches = response.match(regex)

  if (matches && matches.length > 0) {
    return matches[0]
  } else {
    return null // or handle the case when no number is found
  }
}

export const formatTime = (startTime, endTime) => {
  const startDateTime = new Date(startTime)
  const endDateTime = new Date(endTime)
  const formattedStartDate = startDateTime.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  })
  const formattedStartTime = startDateTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  })
  const formattedEndTime = endDateTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  })
  return `${formattedStartDate} ${formattedStartTime} - ${formattedEndTime}`
}

export const formatTimeStartEndTime = (startTime, endTime) => {
  const startDateTime = new Date(startTime)
  const endDateTime = new Date(endTime)
  const formattedStartDate = startDateTime.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  })
  const formattedEndDate = endDateTime.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  })
  const formattedStartTime = startDateTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  })
  const formattedEndTime = endDateTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  })
  return `${formattedStartDate} ${formattedStartTime} - ${formattedEndDate} ${formattedEndTime}`
}

export const formatDateAndTime = (originalDate) => {
  const date = new Date(originalDate)
  const year = date.getFullYear().toString().slice(-2) // Get the last two digits of the year
  const month = String(date.getMonth() + 1).padStart(2, "0") // Month is zero-indexed, so add 1
  const day = String(date.getDate()).padStart(2, "0")
  const hours = String(date.getHours()).padStart(2, "0")
  const minutes = String(date.getMinutes()).padStart(2, "0")
  const seconds = String(date.getSeconds()).padStart(2, "0")

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}
export const formatDate = (originalDate, divider) => {
  const date = new Date(originalDate)
  const year = date.getFullYear().toString() // Get the last two digits of the year
  const month = String(date.getMonth() + 1).padStart(2, "0") // Month is zero-indexed, so add 1
  const day = String(date.getDate()).padStart(2, "0")

  return divider
    ? `${day}${divider}${month}${divider}${year}`
    : `${day}-${month}-${year}`
}

export const convertToFormattedDate = (bsonTime) => {
  const date = new Date(bsonTime)

  // Extract individual components
  const month = (date.getMonth() + 1).toString().padStart(2, "0")
  const day = date.getDate().toString().padStart(2, "0")
  const year = date.getFullYear()

  // Create the custom format
  const formattedDate = `${month}/${day}/${year}`

  return formattedDate
}

export const OpenInNewTabLink = (href) => {
  window.open(href, "_blank")
}

export const updateObjectInArray = (array, key, value, updatedProperties) => {
  return array.map((obj) =>
    obj[key] === value ? { ...updatedProperties } : obj
  )
}

export const hasPermission = (user, resource, action) => {
  // Extract permissions from the user's role and custom permissions
  const rolePermissions = user.role?.permissions || []
  const customPermissions = user.customPermissions || []

  // Check role permissions
  const roleHasPermission = rolePermissions.some(
    (perm) => perm.resource === resource && perm.actions.includes(action)
  )

  // Check custom permissions
  const customHasPermission = customPermissions.some(
    (perm) => perm.resource === resource && perm.actions.includes(action)
  )

  // Return true if either role permissions or custom permissions grant access
  return roleHasPermission || customHasPermission
}

export const hasResourcePermission = (user, resource) => {
  // Extract permissions from the user's role
  const rolePermissions = user.role?.permissions || []

  // Check if any permission is for the 'Car' resource
  const hasCarResource = rolePermissions.some(
    (perm) => perm.resource === resource
  )

  // Return true if 'Car' resource is found
  return hasCarResource
}

export function convertColorsArray(colors) {
  return colors?.map((color) => ({
    value: color.colorName,
    name: color.colorName,
  }))
}
