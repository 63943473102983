import React from "react"
import "./NotificationCard.scss"
import usericon from "../../images/common/usericon.svg"
import usericonlight from "../../images/common/user-light.svg"
import readicon from "../../images/common/dotRead.svg"
import unreadicon from "../../images/common/dotUnread.svg"

export const NotificationMobile = ({
  notification,
  handleNotificationClick,
}) => {
  const formatRelativeTime = (dateString) => {
    const now = new Date()
    const date = new Date(dateString)
    const diffInSeconds = Math.floor((now - date) / 1000)

    const units = [
      { name: "y", seconds: 60 * 60 * 24 * 365 },
      { name: "m", seconds: 60 * 60 * 24 * 30 },
      { name: "w", seconds: 60 * 60 * 24 * 7 }, // Week added here
      { name: "d", seconds: 60 * 60 * 24 },
      { name: "h", seconds: 60 * 60 },
      { name: "m", seconds: 60 },
    ]

    for (let unit of units) {
      const interval = Math.floor(diffInSeconds / unit.seconds)
      if (interval >= 1) {
        return `${interval}${unit.name}${interval > 1 ? "" : ""}`
      }
    }

    return "just now"
  }

  console.log(notification)

  return (
    <li
      key={notification.title}
      className={
        notification.isRead
          ? "notification-card read"
          : "notification-card unread"
      }
      onClick={() => handleNotificationClick(notification)}
    >
      <div
        className={
          notification.isRead
            ? "notification-wrapper read"
            : "notification-wrapper unread"
        }
      >
        <div className="c1">
          <img
            src={!notification.isRead ? usericon : usericonlight}
            alt=""
            className="icon"
          />
        </div>
        <div className="c2">
          <p className="text-title">{notification.title}</p>
          <div className="flex aic">
            <p className="text-sub-title">
              {notification.body}
              {` · `}
            </p>
            <small className="text-light">
              {formatRelativeTime(notification.createdAt)}
            </small>
          </div>
          <div className="text-light">
            {notification._user._officialBrand ? (
              <p>
                {notification._user.role.name.toLowerCase().includes("manager")
                  ? "Manager"
                  : "Staff"}{" "}
                at {notification._user._branch.name} -{" "}
                {notification._user._officialBrand.name}
              </p>
            ) : notification._user._admin ? (
              <p>Carmate Admin</p>
            ) : null}
          </div>
        </div>
        <div className="c3">
          {notification.isRead ? (
            <img src={readicon} alt="" />
          ) : (
            <img src={unreadicon} alt="" />
          )}
        </div>
      </div>
    </li>
  )
}
