import {
  STATIC_DATA_LIST_FAIL,
  STATIC_DATA_LIST_REQUEST,
  STATIC_DATA_LIST_RESET,
  STATIC_DATA_LIST_SUCCESS,
} from "../constants/staticDataConstants"

export const staticDataListReducer = (
  state = { staticData: [], loading: true },
  action
) => {
  switch (action.type) {
    case STATIC_DATA_LIST_REQUEST:
      return { loading: true, staticData: [] }
    case STATIC_DATA_LIST_SUCCESS:
      return {
        loading: false,
        staticData: action.payload,
      }
    case STATIC_DATA_LIST_RESET:
      return {
        loading: true,
        staticData: [],
      }
    case STATIC_DATA_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
