export const OPPORTUNITY_LIST_REQUEST = "OPPORTUNITY_LIST_REQUEST"
export const OPPORTUNITY_LIST_SUCCESS = "OPPORTUNITY_LIST_SUCCESS"
export const OPPORTUNITY_LIST_FAIL = "OPPORTUNITY_LIST_FAIL"

export const OPPORTUNITY_UPDATE_REQUEST = "OPPORTUNITY_UPDATE_REQUEST"
export const OPPORTUNITY_UPDATE_SUCCESS = "OPPORTUNITY_UPDATE_SUCCESS"
export const OPPORTUNITY_UPDATE_FAIL = "OPPORTUNITY_UPDATE_FAIL"
export const OPPORTUNITY_UPDATE_RESET = "OPPORTUNITY_UPDATE_RESET"

export const OPPORTUNITY_DETAIL_REQUEST = "OPPORTUNITY_DETAIL_REQUEST"
export const OPPORTUNITY_DETAIL_SUCCESS = "OPPORTUNITY_DETAIL_SUCCESS"
export const OPPORTUNITY_DETAIL_FAIL = "OPPORTUNITY_DETAIL_FAIL"

export const OPPORTUNITY_CREATE_REQUEST = "OPPORTUNITY_CREATE_REQUEST"
export const OPPORTUNITY_CREATE_SUCCESS = "OPPORTUNITY_CREATE_SUCCESS"
export const OPPORTUNITY_CREATE_FAIL = "OPPORTUNITY_CREATE_FAIL"
export const OPPORTUNITY_CREATE_RESET = "OPPORTUNITY_CREATE_RESET"

export const OPPORTUNITY_DELETE_REQUEST = "OPPORTUNITY_DELETE_REQUEST"
export const OPPORTUNITY_DELETE_SUCCESS = "OPPORTUNITY_DELETE_SUCCESS"
export const OPPORTUNITY_DELETE_FAIL = "OPPORTUNITY_DELETE_FAIL"
export const OPPORTUNITY_DELETE_RESET = "OPPORTUNITY_DELETE_RESET"
