export const GENERATION_LIST_REQUEST = "GENERATION_LIST_REQUEST"
export const GENERATION_LIST_SUCCESS = "GENERATION_LIST_SUCCESS"
export const GENERATION_LIST_FAIL = "GENERATION_LIST_FAIL"
export const GENERATION_LIST_RESET = "GENERATION_LIST_RESET"

export const GENERATION_CREATE_REQUEST = "GENERATION_CREATE_REQUEST"
export const GENERATION_CREATE_SUCCESS = "GENERATION_CREATE_SUCCESS"
export const GENERATION_CREATE_FAIL = "GENERATION_CREATE_FAIL"
export const GENERATION_CREATE_RESET = "GENERATION_CREATE_RESET"

export const GENERATION_DETAIL_REQUEST = "GENERATION_DETAIL_REQUEST"
export const GENERATION_DETAIL_SUCCESS = "GENERATION_DETAIL_SUCCESS"
export const GENERATION_DETAIL_FAIL = "GENERATION_DETAIL_FAIL"
export const GENERATION_DETAIL_RESET = "GENERATION_DETAIL_RESET"

export const GENERATION_UPDATE_REQUEST = "GENERATION_UPDATE_REQUEST"
export const GENERATION_UPDATE_SUCCESS = "GENERATION_UPDATE_SUCCESS"
export const GENERATION_UPDATE_FAIL = "GENERATION_UPDATE_FAIL"
export const GENERATION_UPDATE_RESET = "GENERATION_UPDATE_RESET"

export const GENERATION_DELETE_REQUEST = "GENERATION_DELETE_REQUEST"
export const GENERATION_DELETE_SUCCESS = "GENERATION_DELETE_SUCCESS"
export const GENERATION_DELETE_FAIL = "GENERATION_DELETE_FAIL"
export const GENERATION_DELETE_RESET = "GENERATION_DELETE_RESET"
