import {
  SELLER_LAST_ACTIVITY_ANALYTICS_FAIL,
  SELLER_LAST_ACTIVITY_ANALYTICS_REQUEST,
  SELLER_LAST_ACTIVITY_ANALYTICS_RESET,
  SELLER_LAST_ACTIVITY_ANALYTICS_SUCCESS,
} from "../constants/sellerAnalyticsConstants"

export const sellerLastActivityReducer = (
  state = { sellers: [], loading: true },
  action
) => {
  switch (action.type) {
    case SELLER_LAST_ACTIVITY_ANALYTICS_REQUEST:
      return { loading: true, sellers: [] }
    case SELLER_LAST_ACTIVITY_ANALYTICS_SUCCESS:
      return {
        loading: false,
        sellers: action.payload.sellers,
      }
    case SELLER_LAST_ACTIVITY_ANALYTICS_RESET:
      return {
        loading: false,
        sellers: [],
      }
    case SELLER_LAST_ACTIVITY_ANALYTICS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
