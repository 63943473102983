import React from "react"
import { Button, CircularProgress } from "@mui/material"
import closeIcon from "../../images/common/cross-lg.svg"

const InnerHeaderButton = (props) => {
  return (
    <Button
      className={props.className}
      style={{
        backgroundColor: props.color
          ? props.color
          : props.disabled
          ? "#808080"
          : props.variant === "primary"
          ? "#1c86b8"
          : "#03a00c",

        borderRadius: "0",
        padding: "4px 16px",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFFFFF",

        textTransform: "uppercase",
        cursor: "pointer",
      }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.loading ? (
        <div style={{ width: 50, height: 25 }}>
          <CircularProgress color="inherit" size={25} />
        </div>
      ) : (
        props.children
      )}
    </Button>
  )
}

export const CustomButton = ({
  disabled,
  submitHandler,
  label,
  variant,
  onClick,
  type,
  drawer,
}) => {
  const style = {
    primary: {
      backgroundColor: !disabled ? "#03a00c" : "#808080",
      borderRadius: "0",
      padding: "8px 16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#FFFFFF",
      height: "100%",
      textTransform: "uppercase",
      cursor: "pointer",
    },
    secondary: {
      backgroundColor: "#DADADA",
      borderRadius: "2px",
      padding: "4px 12px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#333333",
      height: "100%",
      textTransform: "none",
      cursor: "pointer",
    },
  }

  return drawer ? (
    <div
      style={{
        marginTop: 40,
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
      }}
    >
      <Button
        style={variant === "secondary" ? style.secondary : style.primary}
        disabled={disabled}
        onClick={submitHandler || onClick}
        type={type}
      >
        {label}
      </Button>
    </div>
  ) : (
    <Button
      style={variant === "secondary" ? style.secondary : style.primary}
      disabled={disabled}
      onClick={submitHandler || onClick}
      type={type}
    >
      {label}
    </Button>
  )
}

export const CustomButtonMobile = ({
  text = "Click Me",
  onClick,
  backgroundColor = "#1c86b8",
  hoverColor = "#166a94",
  textColor = "#fff",
  width = "100%",
  padding = "12px 16px",
  borderRadius = "0px",
  ...props
}) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        backgroundColor,
        color: textColor,
        textTransform: "none", // Keeps the text as provided without uppercase transformation
        fontWeight: "400",
        height: 40,
        width,
        padding,
        boxShadow: "none", // Removes the default box shado
        "&:hover": {
          backgroundColor: hoverColor,
        },
        borderRadius,
      }}
      {...props}
    >
      {text}
    </Button>
  )
}

export default InnerHeaderButton

export const CloseButton = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <img src={closeIcon} alt="" />
    </Button>
  )
}
