import { Snackbar } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  BRAND_CREATE_RESET,
  BRAND_DETAIL_RESET,
  BRAND_UPDATE_RESET,
} from "../../state/constants/brandConstants"
import {
  CAR_CREATE_RESET,
  CAR_DELETE_RESET,
  CAR_UPDATE_RESET,
} from "../../state/constants/carMPConstants"

import { GENERATION_DELETE_RESET } from "../../state/constants/generationConstants"
import {
  OPPORTUNITY_DELETE_RESET,
  OPPORTUNITY_UPDATE_RESET,
} from "../../state/constants/opportunityConstants"
import {
  REVIEW_CREATE_RESET,
  REVIEW_UPDATE_RESET,
} from "../../state/constants/reviewConstants"

import {
  YEAR_CREATE_RESET,
  YEAR_DELETE_RESET,
  YEAR_UPDATE_RESET,
} from "../../state/constants/yearConstants"
import {
  TRIM_CREATE_RESET,
  TRIM_DELETE_RESET,
  TRIM_UPDATE_RESET,
} from "../../state/constants/trimConstants"

const ErrorHandling = ({ setCarId }) => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState("")

  const dispatch = useDispatch()

  const carCreate = useSelector((state) => state.carMarketplace.carCreate)
  const {
    loading: loadingCarCreate,
    success: successCarCreate,
    error: errorCarCreate,
  } = carCreate

  const carUpdate = useSelector((state) => state.carMarketplace.carUpdate)
  const {
    loading: loadingCarUpdate,
    success: successCarUpdate,
    error: errorCarUpdate,
  } = carUpdate

  const carDelete = useSelector((state) => state.carMarketplace.carDelete)
  const { success: successCarDelete, error: errorCarDelete } = carDelete

  const carDetail = useSelector((state) => state.carMarketplace.carDetail)
  const { error: errorCarDetail } = carDetail

  // Opportunites

  const opportunityUpdate = useSelector(
    (state) => state.opportunity.opportunityUpdate
  )
  const { success: opportunityUpdateSuccess, error: opportunityUpdateError } =
    opportunityUpdate

  const opportunityDelete = useSelector(
    (state) => state.opportunity.opportunityDelete
  )
  const { success: opportunityDeleteSuccess, error: opportunityDeleteError } =
    opportunityDelete

  const brandDetail = useSelector((state) => state.carCatalogue.brandDetail)
  const { error: errorBrandDetail } = brandDetail

  const brandCreate = useSelector((state) => state.carCatalogue.brandCreate)
  const { success: successBrandCreate, error: errorBrandCreate } = brandCreate

  const brandUpdate = useSelector((state) => state.carCatalogue.brandUpdate)
  const { success: successBrandUpdate, error: errorBrandUpdate } = brandUpdate

  const generationDelete = useSelector(
    (state) => state.carCatalogue.generationDelete
  )
  const { error: errorGenerationDelete } = generationDelete

  const yearDelete = useSelector((state) => state.carCatalogue.yearDelete)
  const { error: errorYearDelete, success: successYearDelete } = yearDelete

  const yearUpdate = useSelector((state) => state.carCatalogue.yearUpdate)
  const { error: errorYearUpdate, success: successYearUpdate } = yearUpdate
  const yearCreate = useSelector((state) => state.carCatalogue.yearCreate)
  const { error: errorYearCreate, success: successYearCreate } = yearCreate

  const trimDelete = useSelector((state) => state.carCatalogue.trimDelete)
  const { error: errorTrimDelete, success: trimDeleteSuccess } = trimDelete
  const trimCreate = useSelector((state) => state.carCatalogue.trimCreate)
  const { error: errorTrimCreate, success: trimCreateSuccess } = trimCreate
  const trimUpdate = useSelector((state) => state.carCatalogue.trimUpdate)
  const { error: errorTrimUpdate, success: trimUpdateSuccess } = trimUpdate

  const reviewCreate = useSelector((state) => state.review.reviewCreate)
  const {
    loading: loadingReviewCreate,
    success: successReviewCreate,
    error: errorReviewCreate,
  } = reviewCreate
  const reviewUpdate = useSelector((state) => state.review.reviewUpdate)
  const {
    loading: loadingReviewUpdate,
    success: successReviewUpdate,
    error: errorReviewUpdate,
  } = reviewUpdate

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpen(false)
  }

  useEffect(() => {
    // Cars
    if (successCarCreate) {
      setOpen(true)
      setMessage("Car created successfully")
      setTimeout(() => {
        dispatch({ type: CAR_CREATE_RESET })
      }, 1000)
    } else if (errorCarCreate) {
      setOpen(true)
      setMessage(errorCarCreate)
      setTimeout(() => {
        dispatch({ type: CAR_CREATE_RESET })
      }, 1000)
    } else if (successCarUpdate) {
      setOpen(true)
      setMessage("Car updated successfully")
      setCarId(null)
      setTimeout(() => {
        dispatch({ type: CAR_UPDATE_RESET })
      }, 1000)
    } else if (errorCarUpdate) {
      setOpen(true)
      setMessage(errorCarUpdate)
      setTimeout(() => {
        dispatch({ type: CAR_UPDATE_RESET })
      }, 1000)
    } else if (loadingCarCreate) {
      setOpen(true)
      setMessage("Creating car...")
    } else if (successCarDelete) {
      setOpen(true)
      setMessage("Car Deleted Successfully")
      setTimeout(() => {
        dispatch({ type: CAR_DELETE_RESET })
      }, 1000)
    } else if (errorCarDelete) {
      setOpen(true)
      setMessage(errorCarDelete)
      setTimeout(() => {
        dispatch({ type: CAR_DELETE_RESET })
      }, 1000)
    } else if (loadingCarUpdate) {
      setOpen(true)
      setMessage("Updating car...")
    } else if (errorCarDetail) {
      setOpen(true)
      setMessage(errorCarDetail)
      setTimeout(() => {
        dispatch({ type: CAR_DELETE_RESET })
      }, 1000)
    }

    // Opportunity
    if (opportunityUpdateError) {
      setOpen(true)
      setMessage(opportunityUpdateError)
      setTimeout(() => {
        dispatch({ type: OPPORTUNITY_UPDATE_RESET })
      }, 1000)
    } else if (opportunityUpdateSuccess) {
      setOpen(true)
      setMessage("Opportunity Updated Successfully")
      setTimeout(() => {
        dispatch({ type: OPPORTUNITY_UPDATE_RESET })
      }, 1000)
    } else if (opportunityDeleteError) {
      setOpen(true)
      setMessage(opportunityDeleteError)
      setTimeout(() => {
        dispatch({ type: OPPORTUNITY_DELETE_RESET })
      }, 1000)
    } else if (opportunityDeleteSuccess) {
      setOpen(true)
      setMessage("Opportunity Deleted Successfully")
      setTimeout(() => {
        dispatch({ type: OPPORTUNITY_DELETE_RESET })
      }, 1000)
    }

    // Catalouges

    if (errorYearDelete) {
      setOpen(true)
      setMessage(errorYearDelete)
      setTimeout(() => {
        dispatch({ type: YEAR_DELETE_RESET })
      }, 1000)
    } else if (errorYearUpdate) {
      setOpen(true)
      setMessage(errorYearUpdate)
      setTimeout(() => {
        dispatch({ type: YEAR_UPDATE_RESET })
      }, 1000)
    } else if (errorGenerationDelete) {
      setOpen(true)
      setMessage(errorGenerationDelete)
      setTimeout(() => {
        dispatch({ type: GENERATION_DELETE_RESET })
      }, 1000)
    } else if (errorBrandCreate || errorBrandUpdate) {
      setOpen(true)
      setMessage(errorBrandCreate || errorBrandUpdate)
      setTimeout(() => {
        dispatch({ type: BRAND_CREATE_RESET })
        dispatch({ type: BRAND_UPDATE_RESET })
      }, 1000)
    } else if (successBrandCreate) {
      setOpen(true)
      setMessage("Brand Created Successfully")
      setTimeout(() => {
        dispatch({ type: BRAND_CREATE_RESET })
      }, 1000)
    } else if (successBrandUpdate) {
      setOpen(true)
      setMessage("Brand Updated Successfully")
      setTimeout(() => {
        dispatch({ type: BRAND_UPDATE_RESET })
      }, 1000)
    } else if (errorBrandDetail) {
      setOpen(true)
      setMessage(errorBrandDetail)
      setTimeout(() => {
        dispatch({ type: BRAND_DETAIL_RESET })
      }, 1000)
    }

    if (successReviewCreate) {
      setOpen(true)
      setMessage("Review created successfully")
      setTimeout(() => {
        dispatch({ type: REVIEW_CREATE_RESET })
      }, 1000)
    } else if (errorReviewCreate) {
      setOpen(true)
      setMessage(errorReviewCreate)
      setTimeout(() => {
        dispatch({ type: REVIEW_CREATE_RESET })
      }, 1000)
    } else if (successReviewUpdate) {
      setOpen(true)
      setMessage("Review updated successfully")
      setCarId(null)
      setTimeout(() => {
        dispatch({ type: REVIEW_UPDATE_RESET })
      }, 1000)
    } else if (errorReviewUpdate) {
      setOpen(true)
      setMessage(errorReviewUpdate)
      setTimeout(() => {
        dispatch({ type: REVIEW_UPDATE_RESET })
      }, 1000)
    } else if (loadingReviewCreate) {
      setOpen(true)
      setMessage("Creating review...")
    } else if (loadingReviewUpdate) {
      setOpen(true)
      setMessage("Updating review...")
    }

    if (errorTrimUpdate || errorTrimCreate || errorTrimDelete) {
      setOpen(true)
      setMessage(errorTrimUpdate || errorTrimCreate || errorTrimDelete)
      setTimeout(() => {
        dispatch({ type: TRIM_UPDATE_RESET })
        dispatch({ type: TRIM_CREATE_RESET })
        dispatch({ type: TRIM_DELETE_RESET })
      }, 500)
    } else if (trimCreateSuccess || trimUpdateSuccess || trimDeleteSuccess) {
      setTimeout(() => {
        trimUpdateSuccess && setOpen(true)
        trimUpdateSuccess && setMessage("Trim Updated Successfully")
        trimUpdateSuccess && dispatch({ type: TRIM_UPDATE_RESET })
        trimCreateSuccess && setOpen(true)
        trimCreateSuccess && setMessage("Trim Created Successfully")
        trimCreateSuccess && dispatch({ type: TRIM_CREATE_RESET })
        trimDeleteSuccess && dispatch({ type: TRIM_DELETE_RESET })
      }, 500)
    }
    if (errorYearUpdate || errorYearCreate || errorYearDelete) {
      setOpen(true)
      setMessage(errorYearUpdate || errorYearCreate || errorYearDelete)
      setTimeout(() => {
        dispatch({ type: YEAR_UPDATE_RESET })
        dispatch({ type: YEAR_CREATE_RESET })
        dispatch({ type: YEAR_DELETE_RESET })
      }, 500)
    } else if (successYearCreate || successYearUpdate || successYearDelete) {
      setTimeout(() => {
        successYearUpdate && setOpen(true)
        successYearUpdate && setMessage("Year Updated Successfully")
        successYearUpdate && dispatch({ type: YEAR_UPDATE_RESET })
        successYearCreate && setOpen(true)
        successYearCreate && setMessage("Year Created Successfully")
        successYearCreate && dispatch({ type: YEAR_CREATE_RESET })
        successYearDelete && dispatch({ type: YEAR_DELETE_RESET })
      }, 500)
    }
  }, [
    // Cars
    successCarCreate,
    errorCarCreate,
    successCarUpdate,
    errorCarUpdate,
    loadingCarCreate,
    successCarDelete,
    errorCarDelete,
    loadingCarUpdate,
    errorCarDetail,
    // Opportunity
    opportunityUpdateError,
    opportunityUpdateSuccess,
    opportunityDeleteSuccess,
    opportunityDeleteError,
    // Catalouges
    successYearDelete,
    errorYearDelete,
    errorGenerationDelete,
    errorBrandCreate,
    errorBrandUpdate,
    successBrandCreate,
    successBrandUpdate,
    errorBrandDetail,
    reviewCreate,
    reviewUpdate,
    errorReviewCreate,
    errorReviewUpdate,
    // Trim
    errorTrimUpdate,
    errorTrimCreate,
    errorTrimDelete,
    trimCreateSuccess,
    trimUpdateSuccess,
    trimDeleteSuccess,
    // Year
    errorYearCreate,
    errorYearUpdate,
    errorYearDelete,
    successYearCreate,
    successYearUpdate,
    successYearDelete,

    dispatch,
  ])

  return (
    <div>
      {message && (
        <Snackbar
          open={open}
          autoHideDuration={loadingCarCreate || loadingCarUpdate ? null : 2000}
          onClose={handleClose}
          message={message}
        />
      )}
    </div>
  )
}

export default ErrorHandling
