import { useEffect, useRef } from "react"
import { CloseButton } from "../utils/Button"
import { NotificationMobile } from "./NotificationCard"
import "./NotificationDropdown.scss"

// NotificationDropdown Component
export const NotificationDropdown = ({
  notify,
  handleMarkAllAsRead,
  handleNotificationClick,
  setShowDropdown,
  showDropdown,
}) => {
  return (
    <div className="notification-dropdown">
      <div className="dropdown-header flex jcsb aic">
        <button className="mark-all-button" onClick={handleMarkAllAsRead}>
          Mark all as read
        </button>
        <CloseButton onClick={() => setShowDropdown(!showDropdown)} />
      </div>

      {notify && notify.length > 0 ? (
        <ul className="dropdown-body">
          {notify.map((notification, index) => (
            <NotificationMobile
              key={index}
              notification={notification}
              handleNotificationClick={handleNotificationClick}
            />
          ))}
        </ul>
      ) : (
        <p>No notifications</p>
      )}
    </div>
  )
}

export const NotificationDropdownDesktop = ({
  showDropdown,
  setShowDropdown,
  notify,
  handleNotificationClick,
  handleMarkAllAsRead,
}) => {
  const dropdownRef = useRef(null)

  // To handle outside click for closing dropdown
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false)
      }
    }

    if (showDropdown) {
      document.addEventListener("mousedown", handleOutsideClick)
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick)
    }
  }, [showDropdown, setShowDropdown])

  if (!showDropdown) return null

  return (
    <div className="notification-dropdown" ref={dropdownRef}>
      {/* Close button for mobile view */}

      {notify && notify.length > 0 ? (
        <div className="">
          <button className="mark-all-button" onClick={handleMarkAllAsRead}>
            Mark All as Read
          </button>
          <ul>
            {notify.map((notification, index) => (
              <NotificationMobile
                key={index}
                notification={notification}
                handleNotificationClick={handleNotificationClick}
              />
            ))}
          </ul>
        </div>
      ) : (
        <div className="notification-tab flex aic jcc">
          <p>No notifications</p>
        </div>
      )}
    </div>
  )
}
