import { Snackbar } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

const CustomMessage = () => {
  const [open, setOpen] = useState(false)

  const messageState = useSelector((state) => state.response.messageState)
  const { message: customMessage } = messageState

  useEffect(() => {
    if (customMessage.length) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [customMessage])

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpen(false)
  }

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={customMessage}
      />
    </div>
  )
}

export default CustomMessage
