export const EVENTS_LIST_REQUEST = "EVENTS_LIST_REQUEST"
export const EVENTS_LIST_SUCCESS = "EVENTS_LIST_SUCCESS"
export const EVENTS_LIST_FAIL = "EVENTS_LIST_FAIL"

export const EVENT_CREATE_REQUEST = "EVENT_CREATE_REQUEST"
export const EVENT_CREATE_SUCCESS = "EVENT_CREATE_SUCCESS"
export const EVENT_CREATE_FAIL = "EVENTS_CREATE_FAIL"
export const EVENT_CREATE_RESET = "EVENTS_CREATE_RESET"

export const EVENT_UPDATE_REQUEST = "EVENT_UPDATE_REQUEST"
export const EVENT_UPDATE_SUCCESS = "EVENT_UPDATE_SUCCESS"
export const EVENT_UPDATE_FAIL = "EVENTS_UPDATE_FAIL"
export const EVENT_UPDATE_RESET = "EVENTS_UPDATE_RESET"

export const EVENT_DELETE_REQUEST = "EVENT_DELETE_REQUEST"
export const EVENT_DELETE_SUCCESS = "EVENT_DELETE_SUCCESS"
export const EVENT_DELETE_FAIL = "EVENTS_DELETE_FAIL"
export const EVENT_DELETE_RESET = "EVENTS_DELETE_RESET"
