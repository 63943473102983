import {
  USER_CREATE_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_RESET,
  USER_CREATE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_RESET,
  USER_DELETE_SUCCESS,
  USER_DETAIL_FAIL,
  USER_DETAIL_REQUEST,
  USER_DETAIL_RESET,
  USER_DETAIL_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_RESET,
  USER_UPDATE_SUCCESS,
} from "../constants/userContants"

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true }
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload }
    case USER_LOGOUT:
      return {}
    default:
      return state
  }
}

export const userCreateReducer = (
  state = { success: false, loading: true, user: {} },
  action
) => {
  switch (action.type) {
    case USER_CREATE_REQUEST:
      return { loading: true, success: false, user: {} }
    case USER_CREATE_SUCCESS:
      return { loading: false, success: true, user: action.payload }
    case USER_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case USER_CREATE_RESET:
      return { loading: false, user: {} }
    default:
      return state
  }
}
export const userListReducer = (
  state = { users: [], counts: {}, page: "", pages: "", loading: true },
  action
) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { ...state }
    case USER_LIST_SUCCESS:
      return {
        loading: false,
        users: action.payload.users,
        counts: action.payload.counts,
        page: action.payload.page,
        pages: action.payload.pages,
      }
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userDetailReducer = (
  state = { user: {}, loading: true },
  action
) => {
  switch (action.type) {
    case USER_DETAIL_REQUEST:
      return { ...state }
    case USER_DETAIL_SUCCESS:
      return {
        loading: false,
        user: action.payload,
      }
    case USER_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    case USER_DETAIL_RESET:
      return { user: {} }
    default:
      return state
  }
}

export const userUpdateReducer = (
  state = { success: false, loading: false, user: {} },
  action
) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true, success: false, user: {} }
    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true, user: action.payload }
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_RESET:
      return { loading: false, user: {} }
    default:
      return state
  }
}

export const userDeleteReducer = (
  state = { success: false, loading: false },
  action
) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true, success: false }
    case USER_DELETE_SUCCESS:
      return { loading: false, success: true, user: action.payload.userDeleted }
    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case USER_DELETE_RESET:
      return { loading: false }
    default:
      return state
  }
}
