import {
  LOG_LIST_FAIL,
  LOG_LIST_GROUP_BY_CAR_FAIL,
  LOG_LIST_GROUP_BY_CAR_REQUEST,
  LOG_LIST_GROUP_BY_CAR_SUCCESS,
  LOG_LIST_REQUEST,
  LOG_LIST_SUCCESS,
} from "../constants/logConstants"

export const logListReducer = (state = { loading: true, logs: [] }, action) => {
  switch (action.type) {
    case LOG_LIST_REQUEST:
      return { loading: true, logs: [] }
    case LOG_LIST_SUCCESS:
      return {
        loading: false,
        logs: action.payload.logs,
        page: action.payload.page,
        pages: action.payload.pages,
        count: action.payload.count,
      }
    case LOG_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const logListGroupByCarsReducer = (
  state = { loading: true, logs: [] },
  action
) => {
  switch (action.type) {
    case LOG_LIST_GROUP_BY_CAR_REQUEST:
      return { loading: true, logs: [] }
    case LOG_LIST_GROUP_BY_CAR_SUCCESS:
      return {
        loading: false,
        logs: action.payload.logs,
        page: action.payload.page,
        pages: action.payload.pages,
        count: action.payload.count,
      }
    case LOG_LIST_GROUP_BY_CAR_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
