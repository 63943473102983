import React, { useState } from "react"
import { useNavigate } from "react-router"
// import { useSelector } from "react-redux"
import { hasResourcePermission } from "../../utils/utilsFunctions"
import useAuthUser from "../../state/customHooks/user/useAuthUser"
import "./SideDrawer.scss"

const SideDrawer = ({ closeDrawer }) => {
  const [user] = useAuthUser()
  const [active, setActive] = useState("carct")
  const navigate = useNavigate()

  const navigationHandler = (location) => {
    navigate(location)
    closeDrawer()
  }

  // const notifications = useSelector(
  //   (state) => state.notifications.notificationsChange
  // )
  // const { notify } = notifications

  return (
    <nav className="side-drawer">
      <div className="profile-wrapper"></div>
      <ul>
        <li
          className={active === "Dashboard" ? "list-item active" : "list-item"}
          onClick={() => {
            setActive("Dashboard")
            navigationHandler("/dashboard")
          }}
        >
          Dashboard
        </li>
        {hasResourcePermission(user, "car") && (
          <li
            className={active === "carmp" ? "list-item active" : "list-item"}
            onClick={() => {
              setActive("carmp")
              navigationHandler(
                "/car/list?activeTab=All&status=PENDING&pageNumber=1&keywordType=BRAND&keyword="
              )
            }}
          >
            Cars
          </li>
        )}
        {hasResourcePermission(user, "seller") && (
          <li
            className={active === "seller" ? "list-item active" : "list-item"}
            onClick={() => {
              setActive("seller")
              navigationHandler(
                "/seller/list?sellerType=dealerships&keyword=&tags=&status=ACTIVE&pageNumber=1"
              )
            }}
          >
            Dealerships
          </li>
        )}
        {hasResourcePermission(user, "user") && (
          <li
            className={active === "users" ? "list-item active" : "list-item"}
            onClick={() => {
              setActive("users")
              navigationHandler("/user/list")
            }}
          >
            Users
          </li>
        )}
        {hasResourcePermission(user, "new-car") && (
          <li
            className={
              active === "checklist" ? "list-item active" : "list-item"
            }
            onClick={() => {
              setActive("checklist")
              navigationHandler("/trim/dashboard")
            }}
          >
            Trims Manager
          </li>
        )}
        {hasResourcePermission(user, "catalogue") && (
          <li
            className={active === "database" ? "list-item active" : "list-item"}
            onClick={() => {
              setActive("database")
              navigationHandler("/catalogue")
            }}
          >
            Database
          </li>
        )}
        {hasResourcePermission(user, "logs") && (
          <li
            className={active === "logs" ? "list-item active" : "list-item"}
            onClick={() => {
              setActive("logs")
              navigationHandler("/logs")
            }}
          >
            Logs
          </li>
        )}
        {hasResourcePermission(user, "advertisement") && (
          <li
            className={active === "events" ? "list-item active" : "list-item"}
            onClick={() => {
              setActive("events")
              navigationHandler("/advertisement/list")
            }}
          >
            Advertisements
          </li>
        )}
        {hasResourcePermission(user, "event") && (
          <li
            className={active === "event" ? "list-item active" : "list-item"}
            onClick={() => {
              setActive("event")
              navigationHandler("/event/list?status=SCHEDULED&pageNumber=1")
            }}
          >
            Events
          </li>
        )}
        {hasResourcePermission(user, "auction") && (
          <li
            className={active === "auction" ? "list-item active" : "list-item"}
            onClick={() => {
              setActive("auction")
              navigationHandler("/auction/list")
            }}
          >
            Auctions
          </li>
        )}
        {hasResourcePermission(user, "opportunities") && (
          <li
            className={
              active === "opportunity" ? "list-item active" : "list-item"
            }
            onClick={() => {
              setActive("opportunity")
              navigationHandler("/opportunity/list")
            }}
          >
            Opportunities
          </li>
        )}
        {hasResourcePermission(user, "links") && (
          <li
            className={active === "link" ? "list-item active" : "list-item"}
            onClick={() => {
              setActive("link")
              navigationHandler("/link?pageNumber=1")
            }}
          >
            Link Generator
          </li>
        )}
        {hasResourcePermission(user, "trends") && (
          <li
            className={active === "trend" ? "list-item active" : "list-item"}
            onClick={() => {
              setActive("trend")
              navigationHandler("/trends")
            }}
          >
            Price Trends
          </li>
        )}
        {hasResourcePermission(user, "settings") && (
          <li
            className={active === "settings" ? "list-item active" : "list-item"}
            onClick={() => {
              setActive("settings")
              navigationHandler("/settings/dashboard")
            }}
          >
            Settings
          </li>
        )}
      </ul>
    </nav>
  )
}

export default SideDrawer
