import {
  SALE_CREATE_FAIL,
  SALE_CREATE_REQUEST,
  SALE_CREATE_RESET,
  SALE_CREATE_SUCCESS,
  SALE_DELETE_FAIL,
  SALE_DELETE_REQUEST,
  SALE_DELETE_RESET,
  SALE_DELETE_SUCCESS,
  SALE_DETAIL_FAIL,
  SALE_DETAIL_REQUEST,
  SALE_DETAIL_RESET,
  SALE_DETAIL_SUCCESS,
  SALE_LIST_FAIL,
  SALE_LIST_REQUEST,
  SALE_LIST_RESET,
  SALE_LIST_SUCCESS,
  SALE_UPDATE_FAIL,
  SALE_UPDATE_REQUEST,
  SALE_UPDATE_RESET,
  SALE_UPDATE_SUCCESS,
} from "../constants/saleConstants"

export const saleListReducer = (
  state = { loading: true, sales: [], counts: {} },
  action
) => {
  switch (action.type) {
    case SALE_LIST_REQUEST:
      return { loading: true, sales: [], counts: {} }
    case SALE_LIST_SUCCESS:
      return {
        loading: false,
        sales: action.payload.sales,
        counts: action.payload.staticCount,
        page: action.payload.page,
        pages: action.payload.pages,
        count: action.payload.count,
      }
    case SALE_LIST_FAIL:
      return { loading: false, error: action.payload }
    case SALE_LIST_RESET:
      return { sales: [] }
    default:
      return state
  }
}

export const saleCreateReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case SALE_CREATE_REQUEST:
      return { loading: true, success: false }
    case SALE_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        sale: action.payload,
      }
    case SALE_CREATE_FAIL:
      return { loading: false, error: action.payload, success: false }
    case SALE_CREATE_RESET:
      return { loading: false, success: false }
    default:
      return state
  }
}

export const saleDetailReducer = (
  state = { loading: true, auction: {} },
  action
) => {
  switch (action.type) {
    case SALE_DETAIL_REQUEST:
      return { loading: true, sale: {} }
    case SALE_DETAIL_SUCCESS:
      return {
        loading: false,
        sale: action.payload.sale,
        cars: action.payload.cars,
      }
    case SALE_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    case SALE_DETAIL_RESET:
      return { loading: true, sale: {} }
    default:
      return state
  }
}

export const saleUpdateReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case SALE_UPDATE_REQUEST:
      return { loading: true, success: false }
    case SALE_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        sale: action.payload,
      }
    case SALE_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case SALE_UPDATE_RESET:
      return { loading: false, success: false }
    default:
      return state
  }
}

export const saleDeleteReducer = (
  state = { loading: true, success: false },
  action
) => {
  switch (action.type) {
    case SALE_DELETE_REQUEST:
      return { loading: true, success: false }
    case SALE_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case SALE_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case SALE_DELETE_RESET:
      return { loading: true, success: false }
    default:
      return state
  }
}
