export const YEAR_BY_MODEL_GENERATION_LIST_REQUEST =
  "YEAR_BY_MODEL_GENERATION_LIST_REQUEST"
export const YEAR_BY_MODEL_GENERATION_LIST_SUCCESS =
  "YEAR_BY_MODEL_GENERATION_LIST_SUCCESS"
export const YEAR_BY_MODEL_GENERATION_LIST_FAIL =
  "YEAR_BY_MODEL_GENERATION_LIST_FAIL"

export const YEAR_LIST_REQUEST = "YEAR_LIST_REQUEST"
export const YEAR_LIST_SUCCESS = "YEAR_LIST_SUCCESS"
export const YEAR_LIST_FAIL = "YEAR_LIST_FAIL"
export const YEAR_LIST_RESET = "YEAR_LIST_RESET"

export const YEAR_LIST_REVIEW_REQUEST = "YEAR_LIST_REQUEST"
export const YEAR_LIST_REVIEW_SUCCESS = "YEAR_LIST_SUCCESS"
export const YEAR_LIST_REVIEW_FAIL = "YEAR_LIST_FAIL"
export const YEAR_LIST_REVIEW_RESET = "YEAR_LIST_RESET"

export const YEAR_CREATE_REQUEST = "YEAR_CREATE_REQUEST"
export const YEAR_CREATE_SUCCESS = "YEAR_CREATE_SUCCESS"
export const YEAR_CREATE_FAIL = "YEAR_CREATE_FAIL"
export const YEAR_CREATE_RESET = "YEAR_CREATE_RESET"

export const YEAR_CREATE_RANGE_REQUEST = "YEAR_CREATE_RANGE_REQUEST"
export const YEAR_CREATE_RANGE_SUCCESS = "YEAR_CREATE_RANGE_SUCCESS"
export const YEAR_CREATE_RANGE_FAIL = "YEAR_CREATE_RANGE_FAIL"
export const YEAR_CREATE_RANGE_RESET = "YEAR_CREATE_RANGE_RESET"

export const YEAR_DETAIL_REQUEST = "YEAR_DETAIL_REQUEST"
export const YEAR_DETAIL_SUCCESS = "YEAR_DETAIL_SUCCESS"
export const YEAR_DETAIL_FAIL = "YEAR_DETAIL_FAIL"
export const YEAR_DETAIL_RESET = "YEAR_DETAIL_RESET"

export const YEAR_UPDATE_REQUEST = "YEAR_UPDATE_REQUEST"
export const YEAR_UPDATE_SUCCESS = "YEAR_UPDATE_SUCCESS"
export const YEAR_UPDATE_FAIL = "YEAR_UPDATE_FAIL"
export const YEAR_UPDATE_RESET = "YEAR_UPDATE_RESET"

export const YEAR_DELETE_REQUEST = "YEAR_DELETE_REQUEST"
export const YEAR_DELETE_SUCCESS = "YEAR_DELETE_SUCCESS"
export const YEAR_DELETE_FAIL = "YEAR_DELETE_FAIL"
export const YEAR_DELETE_RESET = "YEAR_DELETE_RESET"
