import {
  NOTE_CREATE_FAIL,
  NOTE_CREATE_REQUEST,
  NOTE_CREATE_RESET,
  NOTE_CREATE_SUCCESS,
  NOTE_DELETE_FAIL,
  NOTE_DELETE_REQUEST,
  NOTE_DELETE_RESET,
  NOTE_DELETE_SUCCESS,
  NOTE_LIST_FAIL,
  NOTE_LIST_REQUEST,
  NOTE_LIST_RESET,
  NOTE_LIST_SUCCESS,
  NOTE_UPDATE_FAIL,
  NOTE_UPDATE_REQUEST,
  NOTE_UPDATE_RESET,
  NOTE_UPDATE_SUCCESS,
  TASK_CREATE_FAIL,
  TASK_CREATE_REQUEST,
  TASK_CREATE_RESET,
  TASK_CREATE_SUCCESS,
  TASK_DELETE_FAIL,
  TASK_DELETE_REQUEST,
  TASK_DELETE_RESET,
  TASK_DELETE_SUCCESS,
  TASK_LIST_FAIL,
  TASK_LIST_REQUEST,
  TASK_LIST_RESET,
  TASK_LIST_SUCCESS,
  TASK_UPDATE_FAIL,
  TASK_UPDATE_REQUEST,
  TASK_UPDATE_RESET,
  TASK_UPDATE_SUCCESS,
} from "../constants/commonConstants"

export const noteListReducer = (
  state = { loading: true, notes: [] },
  action
) => {
  switch (action.type) {
    case NOTE_LIST_REQUEST:
      return { loading: true, notes: [] }
    case NOTE_LIST_SUCCESS:
      return {
        loading: false,
        notes: action.payload,
      }
    case NOTE_LIST_FAIL:
      return { loading: false, error: action.payload }
    case NOTE_LIST_RESET:
      return { loading: true, notes: [] }

    default:
      return state
  }
}

export const noteCreateReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case NOTE_CREATE_REQUEST:
      return { loading: true, success: false }
    case NOTE_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case NOTE_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case NOTE_CREATE_RESET:
      return {}

    default:
      return state
  }
}

export const noteUpdateReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case NOTE_UPDATE_REQUEST:
      return { loading: true, success: false }
    case NOTE_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case NOTE_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case NOTE_UPDATE_RESET:
      return { loading: false, success: false }
    default:
      return state
  }
}

export const noteDeleteReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case NOTE_DELETE_REQUEST:
      return { loading: true, success: false }
    case NOTE_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case NOTE_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case NOTE_DELETE_RESET:
      return { loading: false, success: false }
    default:
      return state
  }
}

export const taskListReducer = (
  state = { loading: true, tasks: [] },
  action
) => {
  switch (action.type) {
    case TASK_LIST_REQUEST:
      return { loading: true, tasks: [] }
    case TASK_LIST_SUCCESS:
      return {
        loading: false,
        tasks: action.payload,
      }
    case TASK_LIST_FAIL:
      return { loading: false, error: action.payload }
    case TASK_LIST_RESET:
      return { loading: true, tasks: [] }
    default:
      return state
  }
}

export const taskCreateReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case TASK_CREATE_REQUEST:
      return { loading: true, success: false }
    case TASK_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case TASK_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case TASK_CREATE_RESET:
      return { loading: false, success: false }

    default:
      return state
  }
}

export const taskUpdateReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case TASK_UPDATE_REQUEST:
      return { loading: true, success: false }
    case TASK_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case TASK_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case TASK_UPDATE_RESET:
      return { loading: false, success: false }
    default:
      return state
  }
}

export const taskDeleteReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case TASK_DELETE_REQUEST:
      return { loading: true, success: false }
    case TASK_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case TASK_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case TASK_DELETE_RESET:
      return { loading: false, success: false }

    default:
      return state
  }
}
