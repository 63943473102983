export const TRIM_LIST_REQUEST = "TRIM_LIST_REQUEST"
export const TRIM_LIST_SUCCESS = "TRIM_LIST_SUCCESS"
export const TRIM_LIST_FAIL = "TRIM_LIST_FAIL"
export const TRIM_LIST_RESET = "TRIM_LIST_RESET"

export const TRIM_CREATE_REQUEST = "TRIM_CREATE_REQUEST"
export const TRIM_CREATE_SUCCESS = "TRIM_CREATE_SUCCESS"
export const TRIM_CREATE_FAIL = "TRIM_CREATE_FAIL"
export const TRIM_CREATE_RESET = "TRIM_CREATE_RESET"

export const TRIM_DETAIL_REQUEST = "TRIM_DETAIL_REQUEST"
export const TRIM_DETAIL_SUCCESS = "TRIM_DETAIL_SUCCESS"
export const TRIM_DETAIL_FAIL = "TRIM_DETAIL_FAIL"
export const TRIM_DETAIL_RESET = "TRIM_DETAIL_RESET"

export const TRIM_UPDATE_REQUEST = "TRIM_UPDATE_REQUEST"
export const TRIM_UPDATE_SUCCESS = "TRIM_UPDATE_SUCCESS"
export const TRIM_UPDATE_FAIL = "TRIM_UPDATE_FAIL"
export const TRIM_UPDATE_RESET = "TRIM_UPDATE_RESET"

export const TRIM_DELETE_REQUEST = "TRIM_DELETE_REQUEST"
export const TRIM_DELETE_SUCCESS = "TRIM_DELETE_SUCCESS"
export const TRIM_DELETE_FAIL = "TRIM_DELETE_FAIL"
export const TRIM_DELETE_RESET = "TRIM_DELETE_RESET"

export const TRIM_YEAR_LIST_REQUEST = "TRIM_YEAR_LIST_REQUEST"
export const TRIM_YEAR_LIST_SUCCESS = "TRIM_YEAR_LIST_SUCCESS"
export const TRIM_YEAR_LIST_FAIL = "TRIM_YEAR_LIST_FAIL"
export const TRIM_YEAR_LIST_RESET = "TRIM_YEAR_LIST_RESET"

export const TRIM_LIST_FILTER_REQUEST = "TRIM_LIST_FILTER_REQUEST"
export const TRIM_LIST_FILTER_SUCCESS = "TRIM_LIST_FILTER_SUCCESS"
export const TRIM_LIST_FILTER_FAIL = "TRIM_LIST_FILTER_FAIL"
export const TRIM_LIST_FILTER_RESET = "TRIM_LIST_FILTER_RESET"
