export const BRAND_LIST_REQUEST = "BRAND_LIST_REQUEST"
export const BRAND_LIST_SUCCESS = "BRAND_LIST_SUCCESS"
export const BRAND_LIST_FAIL = "BRAND_LIST_FAIL"

export const BRAND_LIST_REVIEW_REQUEST = "BRAND_LIST_REVIEW_REQUEST"
export const BRAND_LIST_REVIEW_SUCCESS = "BRAND_LIST_REVIEW_SUCCESS"
export const BRAND_LIST_REVIEW_FAIL = "BRAND_LIST_REVIEW_FAIL"

export const BRAND_CREATE_REQUEST = "BRAND_CREATE_REQUEST"
export const BRAND_CREATE_SUCCESS = "BRAND_CREATE_SUCCESS"
export const BRAND_CREATE_FAIL = "BRAND_CREATE_FAIL"
export const BRAND_CREATE_RESET = "BRAND_CREATE_RESET"

export const BRAND_DETAIL_REQUEST = "BRAND_DETAIL_REQUEST"
export const BRAND_DETAIL_SUCCESS = "BRAND_DETAIL_SUCCESS"
export const BRAND_DETAIL_FAIL = "BRAND_DETAIL_FAIL"
export const BRAND_DETAIL_RESET = "BRAND_DETAIL_RESET"

export const BRAND_UPDATE_REQUEST = "BRAND_UPDATE_REQUEST"
export const BRAND_UPDATE_SUCCESS = "BRAND_UPDATE_SUCCESS"
export const BRAND_UPDATE_FAIL = "BRAND_UPDATE_FAIL"
export const BRAND_UPDATE_RESET = "BRAND_UPDATE_RESET"
