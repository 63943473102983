import {
  GENERATION_DELETE_FAIL,
  GENERATION_DELETE_REQUEST,
  GENERATION_DELETE_RESET,
  GENERATION_DELETE_SUCCESS,
  GENERATION_DETAIL_FAIL,
  GENERATION_DETAIL_REQUEST,
  GENERATION_DETAIL_RESET,
  GENERATION_DETAIL_SUCCESS,
  GENERATION_LIST_FAIL,
  GENERATION_LIST_REQUEST,
  GENERATION_LIST_RESET,
  GENERATION_LIST_SUCCESS,
} from "../constants/generationConstants"

export const generationListReducer = (
  state = { generations: [], loading: true },
  action
) => {
  switch (action.type) {
    case GENERATION_LIST_REQUEST:
      return { loading: true, generations: [] }
    case GENERATION_LIST_SUCCESS:
      return {
        loading: false,
        generations: action.payload.generations,
        page: action.payload.page,
        pages: action.payload.pages,
      }
    case GENERATION_LIST_FAIL:
      return { loading: false, error: action.payload }
    case GENERATION_LIST_RESET:
      return { loading: true, generations: [] }

    default:
      return state
  }
}

export const generationDeleteReducer = (
  state = { success: false, loading: true },
  action
) => {
  switch (action.type) {
    case GENERATION_DELETE_REQUEST:
      return { loading: true, success: false }
    case GENERATION_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case GENERATION_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case GENERATION_DELETE_RESET:
      return { loading: false, success: false }

    default:
      return state
  }
}

export const generationDetailReducer = (
  state = { generation: {}, loading: false },
  action
) => {
  switch (action.type) {
    case GENERATION_DETAIL_REQUEST:
      return { loading: true, generation: {} }
    case GENERATION_DETAIL_SUCCESS:
      return {
        loading: false,
        generation: action.payload.generation,
      }
    case GENERATION_DETAIL_RESET:
      return {
        loading: false,
        generation: {},
      }
    case GENERATION_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
