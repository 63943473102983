import axios from "axios"
import {
  REACT_APP_ENV,
  REACT_APP_LOCAL_DEV_URL,
  REACT_APP_PUBLIC_DEV_URL,
  REACT_APP_PUBLIC_PROD_URL,
} from "./base"

// import { store } from "../state/store"
// import { logout } from "../state/actions/userActions"

const instance = axios.create({
  baseURL:
    REACT_APP_ENV === "LOCAL-DEV"
      ? REACT_APP_LOCAL_DEV_URL
      : REACT_APP_ENV === "PUBLIC-DEV"
      ? REACT_APP_PUBLIC_DEV_URL
      : REACT_APP_ENV === "PUBLIC-PROD"
      ? REACT_APP_PUBLIC_PROD_URL
      : null,
})

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // Any status code that falls within the range of 2xx will trigger this function
    return response
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx will trigger this function
    // if (error.response && error.response.status === 401) {
    //   // If the response status is 401, call the logout action
    //   store.dispatch(logout())
    // }
    return Promise.reject(error) // Return any other errors to be handled elsewhere
  }
)

export default instance
