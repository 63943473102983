export const MODEL_LIST_REQUEST = "MODEL_LIST_REQUEST"
export const MODEL_LIST_SUCCESS = "MODEL_LIST_SUCCESS"
export const MODEL_LIST_FAIL = "MODEL_LIST_FAIL"

export const MODEL_LIST_REVIEW_REQUEST = "MODEL_LIST_REVIEW_REQUEST"
export const MODEL_LIST_REVIEW_SUCCESS = "MODEL_LIST_REVIEW_SUCCESS"
export const MODEL_LIST_REVIEW_FAIL = "MODEL_LIST_REVIEW_FAIL"

export const MODEL_CREATE_REQUEST = "MODEL_CREATE_REQUEST"
export const MODEL_CREATE_SUCCESS = "MODEL_CREATE_SUCCESS"
export const MODEL_CREATE_FAIL = "MODEL_CREATE_FAIL"
export const MODEL_CREATE_RESET = "MODEL_CREATE_RESET"

export const MODEL_DETAIL_REQUEST = "MODEL_DETAIL_REQUEST"
export const MODEL_DETAIL_SUCCESS = "MODEL_DETAIL_SUCCESS"
export const MODEL_DETAIL_FAIL = "MODEL_DETAIL_FAIL"

export const MODEL_UPDATE_REQUEST = "MODEL_UPDATE_REQUEST"
export const MODEL_UPDATE_SUCCESS = "MODEL_UPDATE_SUCCESS"
export const MODEL_UPDATE_FAIL = "MODEL_UPDATE_FAIL"
export const MODEL_UPDATE_RESET = "MODEL_UPDATE_RESET"

export const MODEL_DETAIL_LIST_REQUEST = "MODEL_DETAIL_LIST_REQUEST"
export const MODEL_DETAIL_LIST_SUCCESS = "MODEL_DETAIL_LIST_SUCCESS"
export const MODEL_DETAIL_LIST_FAIL = "MODEL_DETAIL_LIST_FAIL"

export const MODEL_DELETE_REQUEST = "MODEL_DELETE_REQUEST"
export const MODEL_DELETE_SUCCESS = "MODEL_DELETE_SUCCESS"
export const MODEL_DELETE_FAIL = "MODEL_DELETE_FAIL"
