export const REACT_APP_PUBLIC_PROD_URL = process.env.REACT_APP_PUBLIC_PROD_URL
export const REACT_APP_PUBLIC_DEV_URL = process.env.REACT_APP_PUBLIC_DEV_URL
export const REACT_APP_LOCAL_DEV_URL = process.env.REACT_APP_LOCAL_DEV_URL
export const REACT_APP_PUBLIC_PROD_URL_WEB =
  process.env.REACT_APP_PUBLIC_PROD_URL_WEB
export const REACT_APP_PUBLIC_DEV_URL_WEB =
  process.env.REACT_APP_PUBLIC_DEV_URL_WEB
export const REACT_APP_LOCAL_DEV_URL_WEB =
  process.env.REACT_APP_LOCAL_DEV_URL_WEB
export const REACT_APP_ENV = process.env.REACT_APP_ENV
export const REACT_APP_FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID

export const baseURL =
  REACT_APP_ENV === "LOCAL-DEV"
    ? REACT_APP_LOCAL_DEV_URL
    : REACT_APP_ENV === "PUBLIC-DEV"
    ? REACT_APP_PUBLIC_DEV_URL
    : REACT_APP_ENV === "PUBLIC-PROD"
    ? REACT_APP_PUBLIC_PROD_URL
    : null
export const baseURLWeb =
  REACT_APP_ENV === "LOCAL-DEV"
    ? REACT_APP_LOCAL_DEV_URL_WEB
    : REACT_APP_ENV === "PUBLIC-DEV"
    ? REACT_APP_PUBLIC_DEV_URL_WEB
    : REACT_APP_ENV === "PUBLIC-PROD"
    ? REACT_APP_PUBLIC_PROD_URL_WEB
    : null
