import {
  EVENTS_LIST_FAIL,
  EVENTS_LIST_REQUEST,
  EVENTS_LIST_SUCCESS,
  EVENT_CREATE_FAIL,
  EVENT_CREATE_REQUEST,
  EVENT_CREATE_RESET,
  EVENT_CREATE_SUCCESS,
  EVENT_DELETE_FAIL,
  EVENT_DELETE_REQUEST,
  EVENT_DELETE_RESET,
  EVENT_DELETE_SUCCESS,
  EVENT_UPDATE_FAIL,
  EVENT_UPDATE_REQUEST,
  EVENT_UPDATE_RESET,
  EVENT_UPDATE_SUCCESS,
} from "../constants/eventsConstants"

export const eventsListReducer = (
  state = { loading: true, events: [] },
  action
) => {
  switch (action.type) {
    case EVENTS_LIST_REQUEST:
      return { loading: true, events: [] }
    case EVENTS_LIST_SUCCESS:
      return {
        loading: false,
        events: action.payload,
      }
    case EVENTS_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const eventCreateReducer = (
  state = { success: false, loading: true },
  action
) => {
  switch (action.type) {
    case EVENT_CREATE_REQUEST:
      return { loading: true, success: false }
    case EVENT_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case EVENT_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case EVENT_CREATE_RESET:
      return {
        loading: true,
        success: false,
      }
    default:
      return state
  }
}
export const eventUpdateReducer = (
  state = { success: false, loading: true },
  action
) => {
  switch (action.type) {
    case EVENT_UPDATE_REQUEST:
      return { loading: true, success: false }
    case EVENT_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case EVENT_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case EVENT_UPDATE_RESET:
      return {
        loading: true,
        success: false,
      }
    default:
      return state
  }
}
export const eventDeleteReducer = (
  state = { success: false, loading: true },
  action
) => {
  switch (action.type) {
    case EVENT_DELETE_REQUEST:
      return { loading: true, success: false }
    case EVENT_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case EVENT_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case EVENT_DELETE_RESET:
      return {
        loading: true,
        success: false,
      }
    default:
      return state
  }
}
