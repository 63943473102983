export const YEAR_SPEC_LIST_REQUEST = "YEAR_SPEC_LIST_REQUEST"
export const YEAR_SPEC_LIST_SUCCESS = "YEAR_SPEC_LIST_SUCCESS"
export const YEAR_SPEC_LIST_FAIL = "YEAR_SPEC_LIST_FAIL"
export const YEAR_SPEC_LIST_RESET = "YEAR_SPEC_LIST_RESET"

export const YEAR_SPEC_CREATE_REQUEST = "YEAR_SPEC_CREATE_REQUEST"
export const YEAR_SPEC_CREATE_SUCCESS = "YEAR_SPEC_CREATE_SUCCESS"
export const YEAR_SPEC_CREATE_FAIL = "YEAR_SPEC_CREATE_FAIL"
export const YEAR_SPEC_CREATE_RESET = "YEAR_SPEC_CREATE_RESET"

export const YEAR_SPEC_DETAIL_REQUEST = "YEAR_SPEC_DETAIL_REQUEST"
export const YEAR_SPEC_DETAIL_SUCCESS = "YEAR_SPEC_DETAIL_SUCCESS"
export const YEAR_SPEC_DETAIL_FAIL = "YEAR_SPEC_DETAIL_FAIL"
export const YEAR_SPEC_DETAIL_RESET = "YEAR_SPEC_DETAIL_RESET"

export const YEAR_SPEC_UPDATE_REQUEST = "YEAR_SPEC_UPDATE_REQUEST"
export const YEAR_SPEC_UPDATE_SUCCESS = "YEAR_SPEC_UPDATE_SUCCESS"
export const YEAR_SPEC_UPDATE_FAIL = "YEAR_SPEC_UPDATE_FAIL"
export const YEAR_SPEC_UPDATE_RESET = "YEAR_SPEC_UPDATE_RESET"

export const YEAR_SPEC_DELETE_REQUEST = "YEAR_SPEC_DELETE_REQUEST"
export const YEAR_SPEC_DELETE_SUCCESS = "YEAR_SPEC_DELETE_SUCCESS"
export const YEAR_SPEC_DELETE_FAIL = "YEAR_SPEC_DELETE_FAIL"
export const YEAR_SPEC_DELETE_RESET = "YEAR_SPEC_DELETE_RESET"
