export const LINK_LIST_REQUEST = "LINK_LIST_REQUEST"
export const LINK_LIST_SUCCESS = "LINK_LIST_SUCCESS"
export const LINK_LIST_FAIL = "LINK_LIST_FAIL"
export const LINK_LIST_RESET = "LINK_LIST_RESET"

export const LINK_CREATE_REQUEST = "LINK_CREATE_REQUEST"
export const LINK_CREATE_SUCCESS = "LINK_CREATE_SUCCESS"
export const LINK_CREATE_FAIL = "LINK_CREATE_FAIL"
export const LINK_CREATE_RESET = "LINK_CREATE_RESET"

export const LINK_DETAIL_REQUEST = "LINK_DETAIL_REQUEST"
export const LINK_DETAIL_SUCCESS = "LINK_DETAIL_SUCCESS"
export const LINK_DETAIL_FAIL = "LINK_DETAIL_FAIL"
export const LINK_DETAIL_RESET = "LINK_DETAIL_RESET"

export const LINK_UPDATE_REQUEST = "LINK_UPDATE_REQUEST"
export const LINK_UPDATE_SUCCESS = "LINK_UPDATE_SUCCESS"
export const LINK_UPDATE_FAIL = "LINK_UPDATE_FAIL"
export const LINK_UPDATE_RESET = "LINK_UPDATE_RESET"

export const LINK_DELETE_REQUEST = "LINK_DELETE_REQUEST"
export const LINK_DELETE_SUCCESS = "LINK_DELETE_SUCCESS"
export const LINK_DELETE_FAIL = "LINK_DELETE_FAIL"
export const LINK_DELETE_RESET = "LINK_DELETE_RESET"
