import {
  LINK_CREATE_FAIL,
  LINK_CREATE_REQUEST,
  LINK_CREATE_RESET,
  LINK_CREATE_SUCCESS,
  LINK_DELETE_FAIL,
  LINK_DELETE_REQUEST,
  LINK_DELETE_RESET,
  LINK_DELETE_SUCCESS,
  LINK_DETAIL_FAIL,
  LINK_DETAIL_REQUEST,
  LINK_DETAIL_RESET,
  LINK_DETAIL_SUCCESS,
  LINK_LIST_FAIL,
  LINK_LIST_REQUEST,
  LINK_LIST_SUCCESS,
  LINK_UPDATE_FAIL,
  LINK_UPDATE_REQUEST,
  LINK_UPDATE_RESET,
  LINK_UPDATE_SUCCESS,
} from "../constants/linkConstants"

export const linkCreateReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case LINK_CREATE_REQUEST:
      return { loading: true, success: false }
    case LINK_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        link: action.payload,
      }
    case LINK_CREATE_FAIL:
      return { loading: false, error: action.payload, success: false }
    case LINK_CREATE_RESET:
      return { loading: false, success: false }
    default:
      return state
  }
}

export const linkListReducer = (
  state = { loading: true, links: [] },
  action
) => {
  switch (action.type) {
    case LINK_LIST_REQUEST:
      return { loading: true, links: [] }
    case LINK_LIST_SUCCESS:
      return {
        loading: false,
        links: action.payload.links,
        page: action.payload.page,
        pages: action.payload.pages,
        count: action.payload.count,
      }
    case LINK_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const linkUpdateReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case LINK_UPDATE_REQUEST:
      return { loading: true, success: false }
    case LINK_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        link: action.payload,
      }
    case LINK_UPDATE_FAIL:
      return { loading: false, error: action.payload, success: false }
    case LINK_UPDATE_RESET:
      return { loading: false, success: false }
    default:
      return state
  }
}

export const linkDeleteReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case LINK_DELETE_REQUEST:
      return { loading: true, success: false }
    case LINK_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        link: action.payload,
      }
    case LINK_DELETE_FAIL:
      return { loading: false, error: action.payload, success: false }
    case LINK_DELETE_RESET:
      return { loading: false, success: false }
    default:
      return state
  }
}

export const linkDetailReducer = (
  state = { loading: true, link: {} },
  action
) => {
  switch (action.type) {
    case LINK_DETAIL_REQUEST:
      return { loading: true, link: {} }
    case LINK_DETAIL_SUCCESS:
      return {
        loading: false,
        link: action.payload,
      }
    case LINK_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    case LINK_DETAIL_RESET:
      return { loading: true, auction: {} }
    default:
      return state
  }
}
